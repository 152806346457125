/**
 *
 * Privacy Policy
 *
 */

import React, {Component} from 'react';
import Helmet from 'react-helmet';
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';

/* Component ==================================================================== */
class PrivacyPolicy extends Component {

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    return (
      <div className="index screen-container">
        <Helmet>
          <title>Privacy Policy</title>
        </Helmet>

        <div className={'main-container container'}>
          <div className={'row justify-content-md-center'}>
            <div className={'col-sm-12 col-lg-8 col-md-10'}>
              <h1 className={'text-center'}>Privacy Policy</h1>
            </div>
          </div>
          <div className={'spacer-20'}/>
          <div className={'row justify-content-md-center'}>
            <div className={'col-sm-12 col-lg-8 col-md-10'}>

              <p>We, <strong>Renzo Solutions Pvt. Ltd</strong> (hereinafter referred to as the
              “<strong>Company</strong>”) a Private Limited
              Company, incorporated under the provisions of the Companies Act, 1956, and having its
              registered office at C/201, Ajit Nagar, JB Nagar, Andheri East Mumbai-400059, India,
              represented by its members, where such expression shall, unless repugnant to the context
              thereof, be deemed to include its respective legal heirs, representatives,
              administrators, permitted successors and assigns. The creator of this Privacy Policy
              ensures our steady commitment to Your privacy with regard to the protection of your
              invaluable information. This privacy policy contains information about an online website
              called <i>www.renzo.in</i> (hereinafter referred to as the “<strong>Website</strong>
              ”). In order to provide You
              with Our uninterrupted use of services, We may collect and, in some circumstances,
              disclose information about you. To ensure better protection of Your privacy, We provide
              this notice explaining Our information collection and disclosure policies, and the
              choices You make about the way Your information is collected and used.
              </p>

              {/*<p>We, <strong>Renzo Solutions Inc</strong> (hereinafter referred to as the <strong>“Company”</strong>) a*/}
              {/*  Company, having its office at 2035 Sunset Lake Road, Newark 19702, USA represented by its members, where*/}
              {/*  such expression shall, unless repugnant to the context thereof, be deemed to include its respective*/}
              {/*  legal heirs, representatives, administrators, permitted successors and assigns. The creator of this*/}
              {/*  Privacy Policy ensures our steady commitment to Your privacy with regard to the protection of your*/}
              {/*  invaluable information. This privacy policy contains information about an online website called*/}
              {/*  www.renzo.in (hereinafter referred to as the <strong>“Website”</strong>). In order to provide You with*/}
              {/*  Our uninterrupted*/}
              {/*  use of services, We may collect and, in some circumstances, disclose information about you. To ensure*/}
              {/*  better protection of Your privacy, We provide this notice explaining Our information collection and*/}
              {/*  disclosure policies, and the choices You make about the way Your information is collected and used.*/}
              {/*</p>*/}

              <p>ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING ACCORDED TO THEM UNDER THIS AGREEMENT.
                FURTHER, ALL HEADING USED HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS OF THE
                AGREEMENT IN ANY MANNER. NEITHER THE USER NOR THE CREATERS OF THIS PRIVACY POLICY MAY USE THE HEADING TO
                INTERPRET THE PROVISIONS CONTAINED WITHIN IT IN ANY MANNER.</p>

              <ol className={'pl-0'}>
                <li>
                  <strong>DEFINITIONS</strong>
                  <ol>
                    <li>“We”, “Our”, and “Us” shall mean and refer to the creators of this privacy
                      policy.
                    </li>
                    <li>“You”, “Your”, “Yourself” and “User” shall mean and refer to natural and
                      legal individuals who use the Website.
                    </li>
                    <li>“Personal Information” shall mean and refer to any personally identifiable
                      information that We may collect from You. For removal of any doubts, please
                      refer to Clause 2.
                    </li>
                    <li>“Third Parties” refer to any website, company or individual apart from the
                      User and the creator of this Website.
                    </li>

                  </ol>
                </li>

                <li>
                  <strong>INFORMATION COLLECTED</strong>
                  <p>We are committed to respecting Your online privacy. We further recognize Your
                    need for appropriate protection and management of any Personal Information You
                    share with us. We may collect the following information:</p>
                  <ol>
                    <li>Personal data of the User such as, but not limited to, Name, Location, User
                      Name, Password, Email ID, Mobile Number, identity proof, address proof, Etc.
                    </li>
                    <li>Tracking Information such as, but not limited to the IP address of your
                      device and Device ID when connected to the Internet.
                    </li>
                  </ol>

                </li>

                <li>
                  <strong>EXTERNAL LINKS ON THE WEBSITE</strong>
                  <p>The Website may include advertisements, hyperlinks to other websites,
                    applications, content or resources. We have no control over such external links
                    present in the Website, which are provided by persons or companies other than
                    Us.</p>
                  <p>You acknowledge and agree that We are not responsible for any collection or
                    disclosure of your Personal Information by any external sites, websites or
                    application, companies or persons, nor do We endorse any advertising, products
                    or other material on or available from such external websites or application,
                    websites or resources.</p>
                  <p>You further acknowledge and agree that We are not liable for any loss or damage
                    which may be incurred by You as a result of the collection and/or disclosure of
                    Your personal information by external websites or applications, sites or
                    resources, or as a result of any reliance placed by You on the completeness,
                    accuracy or existence of any advertising, products or other materials on, or
                    available from such websites or resources. This external Website, websites and
                    resource providers may have their own privacy policies governing the collection,
                    storage, retention and disclosure of Your Personal Information that You may be
                    subject to. We recommend that You enter the external Website and review their
                    privacy policy.</p>
                </li>

                <li>
                  <strong>OUR USE OF YOUR INFORMATION</strong>
                  <p>The information provided by You at the time of registration shall be used to
                    contact You when necessary. For more details about the nature of such
                    communications, please refer to our Terms of Service. Further, Your personal
                    data and Sensitive Personal data may be collected and stored by Us for internal
                    record. </p>
                  <p>We use Your tracking information such as IP addresses, and or Device ID to help
                    identify you and to gather broad demographic information.</p>
                  <p>In case We are acquired by or merged with another company, We shall transfer
                    information disclosed by You and information about You to the company we are
                    acquired by or merged with. In the event of a merger or acquisition, We shall
                    notify You by email/by putting a prominent notice on the Website before Your
                    Personal Information is transferred and becomes subject to a different privacy
                    policy.</p>
                </li>

                <li>
                  <strong>COOKIES</strong>
                  <p>We use data collection devices such as "cookies" on certain pages of our
                    Websites. "Cookies" are small files sited on your hard drive that assist us in
                    providing customized services. We also offer certain features that are only
                    available through the use of a "cookie". Cookies can also help us provide
                    information, which is targeted to your interests. Cookies may be used to
                    identify logged in or registered users.</p>
                  <p>Third party vendors including www.google.com (“Google”) may use cookies to serve
                    ads based on your visits to this Website. You may visit the website of the third
                    party and choose to opt out of the use of cookies for interest-based
                    advertising, if the third party offers such an option. You may choose to opt-out
                    of the Double-click cookie that Google and its partners use for interest-based
                    advertising by visiting Ads Settings. (Alternatively, you can direct users to
                    opt out of a third-party vendor's use of cookies for interest based advertising
                    by visiting aboutads.info.)
                  </p>
                  <p>The website also has enabled the Google Analytics, which allows Google to collect
                    data about users on our website, in addition to Google advertising cookies and
                    anonymous identifiers. You may choose to opt out of this by downloading and
                    installing the Google Analytics opt-out add-on here
                    https://tools.google.com/dlpage/gaoptout/.
                  </p>
                </li>

                <li>
                  <strong>CONFIDENTIALITY</strong>

                  <p>Your information is regarded as confidential and therefore shall not be divulged
                    to any third party, unless if legally required to do so to the appropriate
                    authorities, or if necessary to ensure Users may fully avail of the services of
                    the Website.
                  </p>
                  <p>We shall not sell, share, or rent your personal information to any marketing
                    agencies or any other such companies that indulge in unsolicited communications.
                    Any communication by Us to You shall be undertaken in accordance with Our Terms
                    of Service and Privacy Policy.
                  </p>
                </li>

                <li>
                  <strong>DISCLOSURE OF YOUR INFORMATION TO THIRD PARTIES</strong>
                  <p>Due to the existing regulatory environment, We cannot ensure that all of your
                    Personal Information shall never be disclosed in ways other than those described
                    in this Privacy Policy. Although we use industry standard practices to protect
                    your privacy, we do not promise, and you should not expect, that your personally
                    identifiable information or private communications would always remain private.
                  </p>

                  <ol>
                    <li><p><strong>Other Corporate Entities:</strong> We share much of our data, including Your Personal
                      Information, with Our parent and/ or subsidiaries that are committed to serving Your needs through
                      use of Our Website and related services, throughout the world. Such data shall be shared for the
                      sole purpose of enhancing Your experience of using the Website. To the extent that these entities
                      have access to your information, they shall treat it at least as protectively as they treat
                      information they obtain from their other members. It is possible that We and/or Our subsidiaries,
                      or any combination of such, could merge with or be acquired by another business entity. Should
                      such a combination occur, you should expect that we would share some or all of your information in
                      order to continue to provide the service. You shall receive notice of such event (to the extent it
                      occurs).</p>
                    </li>


                    <li>
                      <p><strong>Law and Order:</strong> We cooperate with law enforcement inquiries, as well as other
                        third parties to enforce laws, such as: intellectual property rights, fraud and other rights. We
                        can, and You so authorise Us, disclose Your Personal Information to law enforcement and other
                        government officials as We, in Our sole discretion, believe necessary or appropriate, in
                        connection with an investigation of fraud, intellectual property infringements, or other
                        activity that is illegal or may expose Us/ Us or You to any legal liability.</p>

                      <p>Any information that you make publically available on the site may be
                        potentially viewed by any party, and by posting such material it is
                        deemed that you consent to share such information with such parties.
                      </p>
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>ACCESSING, REVIEWING AND CHANGING YOUR PROFILE</strong>
                  <p>Following registration, You can review and change the information You submitted
                    at the stage of registration, except Your display name/ user name. An option for
                    facilitating such change shall be present on the Website and such change shall
                    be facilitated by the User. If You change any information, We may keep track of
                    Your old information.
                  </p>

                  <p>Following registration, You can review and change the information You submitted
                    at the stage of registration, except Your display name/ user name. An option for
                    facilitating such change shall be present on the Website and such change shall
                    be facilitated by the User. If You change any information, We may keep track of
                    Your old information.
                  </p>

                  <p>We shall retain in Our files, information You have requested to remove for
                    certain circumstances, such as to resolve disputes, troubleshoot problems and
                    enforce Our terms and conditions. Further, such prior information is never
                    completely removed from Our databases due to technical and legal constraints,
                    including stored 'back up' systems. Therefore, You should not expect that all of
                    Your personally identifiable information shall be completely removed from our
                    databases in response to Your requests.
                  </p>
                </li>

                <li>
                  <strong>SECURITY</strong>
                  <p>We treat data as an asset that must be protected against loss and unauthorised
                    access. We employ many different security techniques to protect such data from
                    unauthorised access by members inside and outside the Company. We follow
                    generally accepted industry standards to protect the Personal Information
                    submitted to Us and information that we have accessed. However, “perfect
                    security” does not exist on the Internet. You therefore agree that any security
                    breaches beyond the control of Our standard security procedures are at Your sole
                    risk and discretion.
                  </p>
                </li>

                <li>
                  <strong>INDEMNITY</strong>
                  <p>You agree and undertake to indemnify us in any suit or dispute by any Third Party
                    arising out of disclosure of Personal Information by You to Third Parties either
                    through Our Website or otherwise and Your use and access of websites, Websites
                    on and resources of Third Parties. We assume no liability for any actions of
                    Third Parties with regard to Your Personal Information, which You may have
                    disclosed to such Third Parties.
                  </p>
                </li>

                <li>
                  <strong>SEVERABILITY</strong>
                  <p>Each paragraph of this privacy policy shall be and remain separate from and
                    independent of and severable from all and any other paragraphs herein except
                    where otherwise expressly indicated or indicated by the context of the
                    agreement. The decision or declaration that one or more of the paragraphs are
                    null and void shall have no effect on the remaining paragraphs of this privacy
                    policy.
                  </p>
                </li>

                <li>
                  <strong>Log Data</strong>
                  <p>When you use the Services, we automatically record Information from the device you
                    use, its software, and your activity using the Services. This may include the device’s Internet
                    Protocol (“IP”) address, browser type, the web page(s) visited before you came to our website,
                    Information you search for on our website, locale preferences, identification numbers associated
                    with your devices, your mobile carrier, date and time stamps associated with transactions, system
                    configuration, metadata concerning your Files, and other interactions with the Services.</p>
                </li>

                <li>
                  <strong>Integrations</strong>
                  <p>Renzo may allow third party integrators (“Integrators“) to create applications and/or tools that
                    supplement or enhance the Services (the “Integrations“). If you choose to access any such
                    Integrations, the Integrators will access (via API) and use information you provide solely for the
                    purpose of supplementing or enhancing the Services through the Integrations.</p>
                </li>

                <li className={'mb-2'}>
                  <strong>Files</strong>
                  <p>Files are encrypted and stored with Amazon’s (AWS) storage service and may be accessed and
                    downloaded by you. </p>
                  <p className={'mb-0'}>Renzo does not access or use your Files other than </p>
                  <ol>
                    <li>in an encrypted form or</li>
                    <li>in aggregated reports that do not contain, nor that can be used to extract, personally
                      identifiable information.</li>
                  </ol>
                </li>

                <li>
                  <strong>Analytics</strong>
                  <p>We may also collect some Information (ourselves or by using third party services) using logging and
                    cookies, such as IP addresses, which can sometimes be correlated with Personal Information. We use
                    this Information for the above purposes and to monitor and analyze use of the Services, for the
                    Services’ technical administration, to increase our Services’ functionality and user-friendliness,
                    and to verify users have the authorization needed for the Services to process their requests.</p>
                </li>

                <li>
                  <strong>Non-private or Non-Personal Information</strong>
                  <p>We may, at our discretion, disclose your non-private, aggregated, or otherwise non-personal
                    Information, such as usage statistics of our Services.</p>
                </li>

                <li>
                  <strong>Changing or Deleting Your Information</strong>
                  <p>If you are registered as the account holder, you may review, update, correct or delete the Personal
                    Information provided in your registration or account profile by changing the “account settings.” In
                    some cases, we may retain copies of your Information and Files if required by law. For questions
                    about your Personal Information or Files on our Services, please contact support@renzo.in We will
                    respond to your inquiry within 30 days.</p>
                </li>

                <li>
                  <strong>Data Retention</strong>
                  <p>We may retain and use your Information as necessary to comply with our legal obligations, resolve
                    disputes, and enforce our agreements. Consistent with these requirements, we will attempt to delete
                    your Information quickly upon request. Please note, however, that there might be latency in deleting
                    Information from our servers and backed-up versions might exist after deletion. In addition, we do
                    not delete from our servers Files that you have in common with other users.</p>

                  <strong>Account Access</strong>
                  <p className={'mb-0'}>You have the ability to:</p>
                  <ul className={'mb-2'}>
                    <li>access, add, delete modify or save Files and other Information in and about your Renzo
                      account;
                    </li>
                    <li>control how your Renzo account may be accessed, modified or deleted.</li>
                  </ul>
                </li>

                <li>
                  <strong>Banking/Card details</strong>
                  <p>If you choose to pay for Renzo by Credit Card or any online mode, your card details are not stored
                    by Renzo and cannot be accessed by Renzo staff. Your credit card details are encrypted and securely
                    stored by Direct Payment Solution to enable Renzo to automatically bill your credit card on a
                    recurring basis,if you have opted for.</p>
                </li>

                <li>
                  <strong>Transfers of Our Business</strong>
                  <p>If we are involved in a merger, acquisition, or sale of all or a portion of our assets, your
                    Information may be transferred as part of that transaction, but we will notify you (for example, via
                    email and/or a prominent notice on our website) of any change in control or use of your Personal
                    Information or Files, or if such Information becomes subject to a different privacy policy.</p>
                </li>

                <li>
                  <strong>AMENDMENT</strong>
                  <p>Our Privacy Policy may change from time to time. Hence we shall post any privacy
                    policy changes on the homepage of the Website or notify You by email.
                  </p>
                </li>
              </ol>


              <p>Last updated on Nov 20,2020</p>
            </div>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }

}

export default PrivacyPolicy;
