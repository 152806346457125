/**
 *
 * Features Online Payment
 * *
 */

import React, {Component} from 'react';
import Helmet from 'react-helmet';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import ReEngage1 from '../../components/ui/ReEngage1';

/* Component ==================================================================== */
class Index extends Component {

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    return (
      <div className="index screen-container">
        <Helmet>
          <title>Online Payments</title>
        </Helmet>

        <div className={'p-3 mt-3 mb-0 text-center'}>
          <div className={'col-md-12 p-lg-2'}>
            <h1>Accept Payments. Online.</h1>
            <p className={'lead'}>Be a payment gateway and collect your payments. No Setup Fees.</p>
            <div className={'spacer-20'}/>

            <p className={'small'}>How it works <br/>
              <i className="fa fa-arrow-down"/>
            </p>
          </div>
        </div>

        <div className={'container'}>


          <div className="d-md-flex flex-md-equal w-150 p-md-3 border-top position-relative center-line">
            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/payments/Online-Payment-Unpaid.png')}/>
                </div>
              </div>

              <h2>Payment Links</h2>
              <p>Generate and share payment link via email, SMS, messenger etc. and get paid immediately. Accepting
                payments is now just a link away.</p>

              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Create a Payment
                    Link.
                  </h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Share the Payment
                    link via email, SMS, messenger etc.</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Get Paid.</h6>
                </li>

              </ul>
            </div>
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/payments/Online-Payment-Unpaid.png')}/>
              </div>
            </div>
          </div>

          <div className="d-md-flex flex-md-equal w-150  p-md-3 position-relative center-line">
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/payments/Online-Payment-Paid.png')}/>
              </div>
            </div>

            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center right-side-box">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/payments/Online-Payment-Paid.png')}/>
                </div>
                <div className={'spacer-20'}/>
              </div>

              <h2>Reports</h2>
              <p>Make business decisions using insights from the real time data and reports available on the
                dashboard.</p>
              <p>Online Payment options.</p>
              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Credit/Debit Cards.
                  </h6>
                </li>
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Wallets.</h6>
                </li>
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Net Banking.</h6>
                </li>
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>UPI.</h6>
                </li>
              </ul>
            </div>
          </div>


          <div className="d-md-flex  p-md-3 border-top">
            <div
              className="flex-shrink-1 w-25 mr-md-3 pt-3 pt-md-5 d-none d-md-none d-sm-none d-lg-block overflow-hidden align-self-center">
              <h2>Pricing</h2>
            </div>
            <div className="w-100 mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center">
              <div className={'d-none d-md-block d-sm-block d-lg-none my-3'}>
                <h3>Pricing</h3>
              </div>
              <div className="my-3 py-3">
                <div className={'d-flex'}>
                  <div className={'flex-shrink-1 mr-2'}>
                    <h3>3%</h3>
                  </div>
                  <div className={'w-100'}>
                    <p>Per transaction as fee to process payments for hotels</p>
                  </div>
                </div>
                <div className={'spacer-20'}/>
                <div className={'text-center'}>
                  <div className={'d-flex w-100'}>
                    <div className={'25'}>
                      <h3>1000 USD</h3>
                      <small>Amount to be charged</small>
                    </div>
                    <div className={'mx-5 flex-shrink-1'}>
                      <span className={'fas fa-arrow-right'}/>
                    </div>
                    <div className={'w-50 d-flex'}>
                      <div className={'w-50'}>
                        <h3>970 USD</h3>
                        <small>To your account</small>
                      </div>
                      <div className={'flex-shrink-1 mx-5 '}>
                        <span className={'fas fa-arrows-alt-h'}/>
                      </div>
                      <div className={'w-50'}>
                        <h3>30 USD</h3>
                        <small>Processing Fee</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'spacer-20'}/>
                <p>If the total amount to be charged from the user is 1000 USD, 970 USD is sent you to your bank account in
                  T(transaction date)+2 days. 30 USD is charged by Renzo as payment handling fees.</p>

                <ul className="list-unstyled d-flex flex-equal">
                  <li className="">
                    <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>No setup fee.</h6>
                  </li>
                  <li className="">
                    <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>No installation fee.</h6>
                  </li>
                  <li className="">
                    <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>No AMC.</h6>
                  </li>
                  <li className="">
                    <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>No charge for links.</h6>
                  </li>
                </ul>

                <p>We keep it simple and easy.</p>
              </div>
            </div>

          </div>
          <ReEngage1/>

        </div>
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false
    };
  }
}

export default Index;