/**
 *
 * Refund and Cancellation
 *
 */

import React, { Component } from 'react';
import Helmet from 'react-helmet';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';

/* Component ==================================================================== */
class Terms extends Component {

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    return (
      <div className="index screen-container">
        <Helmet>
          <title>Refund and Cancellation Policy</title>
        </Helmet>

        <div className={'main-container container'}>
          <div className={'row justify-content-md-center'}>
            <div className={'col-sm-12 col-lg-8 col-md-10'}>
              <h1 className={'text-center'}>Refund &amp; Cancellation Policy</h1>
            </div>
          </div>
          <div className={'spacer-20'}/>
          <div className={'row justify-content-md-center'}>
            <div className={'col-sm-12 col-lg-8 col-md-10'}>
              <p>At Renzo we do everything we can to ensure you are happy and satisfied with our service, but we know
                that sometimes things just won't be right. We’ve made our policy as easy as possible.</p>

              <p>Our Policy for the cancellation and refund will be as follows:</p>

              <ol className={'pl-0'}>
                <li><p><strong>Cancellation Policy: </strong> For Cancellations please contact us you can check contact
                  section for details. Requests received later than 10 business days
                  prior to the end of the current service period will be treated as cancellation of services for the
                  next service period.</p>
                </li>
                <li>
                  <p><strong>Refund Policy : </strong> We’ll give you a refund for a prepaid month if we stop providing
                    the Service and terminate your
                    account without cause. You won’t be entitled to a refund from us under any other circumstances. We
                    may,
                    at our sole discretion, offer a refund if a Member requests one. Our decision on refunds shall be at
                    our sole discretion and shall be final and binding.</p>

                  <p>All Refunds made shall be through the original payment account only within 7-10 working days.</p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  };

  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }

}

export default Terms;