/**
 *
 * Features Payment Management
 * *
 */

import React, {Component} from 'react';
import Helmet from 'react-helmet';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import ReEngage1 from '../../components/ui/ReEngage1';
import {Link} from 'react-router-dom';

/* Component ==================================================================== */
class Index extends Component {

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    return (
      <div className="index screen-container">
        <Helmet>
          <title>Payment Management</title>
        </Helmet>

        <div className={'p-3 mt-3 mb-0 text-center'}>
          <div className={'col-md-12 p-lg-2'}>
            <h1>Payments. Just collect it.</h1>
            <p className={'lead'}>Power your payment with all essentials off the shelf.</p>
            <div className={'spacer-20'}/>

            <p className={'small'}>How it works <br/>
              <i className="fa fa-arrow-down"/>
            </p>
          </div>
        </div>

        <div className={'container'}>


          <div className="d-md-flex flex-md-equal w-150 p-md-3 border-top position-relative center-line">
            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/payments/Payment-Options.png')}/>
                </div>
              </div>

              <h2>Never miss a Payment</h2>
              <p>Give your customer(s) payment option(s), don't let payment options limit your income.</p>
              <p>Choose a mode(s) of payment as per your need.</p>
              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Cash</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Cheque</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Bank Transfer</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Point of Sales
                    Terminal</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Online Payment</h6>
                </li>
              </ul>

              <div className={'spacer-20'}/>
              <Link
                to={'/features/Payments/OnlinePayment'}
                className={'btn btn-success'}>Learn More on Online Payments <i className="fa fa-arrow-right"/></Link>
            </div>
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/payments/Payment-Options.png')}/>
              </div>
            </div>
          </div>

          <div className="d-md-flex flex-md-equal w-150  p-md-3 position-relative center-line">
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/payments/Split-Payment.png')}/>
              </div>
            </div>

            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center right-side-box">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/payments/Split-Payment.png')}/>
                </div>
              </div>

              <h2>Partial or Split payments</h2>
              <p>Be in control of how your payments come in.</p>

              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Collect and record
                    payments in different modes.</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Advance Payments.
                  </h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Pay in Parts.</h6>
                </li>


              </ul>
            </div>
          </div>

          <div className="d-md-flex flex-md-equal w-150 p-md-3 position-relative center-line">
            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/payments/Cash-Payment-Unpaid.png')}/>
                </div>
              </div>

              <h2>Keep it digital</h2>
              <p>Paper can fade and become illegible, but digital records never disappear. Store everything you need
                digitally.</p>

              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Attach receipts</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Collect signature
                    from guests.</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Record mode of
                    payment(s).</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Create reference note(s).</h6>
                </li>
              </ul>
            </div>
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/payments/Cash-Payment-Unpaid.png')}/>
              </div>
            </div>
          </div>

          <ReEngage1/>

        </div>
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false
    };
  }
}

export default Index;