/**
 * Error
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import { ErrorMessages } from '../../constants';


/* Component ==================================================================== */
class Error extends Component {
  static componentName = 'Error';

  static propTypes = {
    text: PropTypes.any,
    full: PropTypes.bool
  };

  render = () => {
    const {text, full} = this.props;
    return (
      <div className={'error site-wrapper' + (full ? ' site-wrapper-absolute' : '')}>
        <div className="site-wrapper-inner">
          <div className="cover-container text-center">
            <span className="glyphicon glyphicon-exclamation-sign large-font"/>
            <h1>Error</h1>
            <p>{text ? text : ErrorMessages.default}</p>
          </div>
        </div>
      </div>
    );
  };
}


/* Export Component ==================================================================== */
export default Error;
