import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Footer, Header } from '../components/ui';
//scenes
import Home from './Home';

import About from './About';
import Contact from './Contact';
import Pricing from './Pricing';
import Partners from './Partners';

import Booking from './features/Booking';
import POS from './features/PointOfSales';
import HouseKeeping from './features/HouseKeeping';
import Features from './features/index';
import ExpenseManagement from './features/ExpenseManagement';
import PaymentManagement from './features/PaymentManagement';
import PaymentOnline from './features/PaymentOnline';
import Reviews from './features/Reviews';

import PrivacyPolicy from './policies/PrivacyPolicy';
import Policies from './policies/index';
import Terms from './policies/Terms';
import Refund from './policies/Refund-Policy';

import NotFound from './NotFound';

const Account = () => (
  <div>
    <Header/>
    <Switch>
      <Route path="/" exact component={Home}/>
      <Route path={'/pricing'} exact component={Pricing}/>
      <Route path={'/about'} exact component={About}/>
      <Route path={'/contact'} exact component={Contact}/>
      <Route path={'/partner'} exact component={Partners}/>


      <Route path={'/features'} exact component={Features}/>
      <Route path={'/features/Booking'} exact component={Booking}/>
      <Route path={'/features/POS'} exact component={POS}/>
      <Route path={'/features/Housekeeping'} exact component={HouseKeeping}/>
      <Route path={'/features/Expenses'} exact component={ExpenseManagement}/>
      <Route path={'/features/Payments'} exact component={PaymentManagement}/>
      <Route path={'/features/Payments/OnlinePayment'} exact component={PaymentOnline}/>
      <Route path={'/features/Reviews'} exact component={Reviews}/>




      <Route path={'/policies'} exact component={Policies}/>
      <Route path={'/policies/privacy'} exact component={PrivacyPolicy}/>
      <Route path={'/policies/terms'} exact component={Terms}/>
      <Route path={'/policies/refund-and-cancellation'} exact component={Refund}/>

      <Route path={'/404-not-found'} exact component={NotFound}/>
      <Route exact component={NotFound}/>

    </Switch>
    <Footer/>
  </div>
);

export default Account;