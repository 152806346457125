/**
 *
 * Partnership Page
 *
 */

import React, {Component} from 'react';
import Helmet from 'react-helmet';
//lib
import AppAPI from '../lib/api';
import {AppConfig, SelectList, Constants} from '../constants';
// Components
import Loading from '../components/general/Loading';
import Error from '../components/general/Error';
import Alerts from '../components/ui/Alerts';
import {Input, Select, SubmitBtn, Textarea} from 'react-formik-ui';
// Forms
import Reaptcha from 'reaptcha';
import {Formik} from 'formik';
import * as Yup from 'yup';

/* Component ==================================================================== */
class Partners extends Component {

  sendMessage = (credentials) => {
    if (credentials) {
      this.setState({loading: true, resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.beta.post('', {
          'name': credentials.name,
          'email': credentials.email,
          'phone': credentials.code + credentials.phone,
          'company': credentials.company_name,
          'campaign': 3,
          'content': `
Name: ${credentials.name},
E-mail: ${credentials.email},
Phone: ${credentials.code + credentials.phone},
Partner Type: ${credentials.partner_type},
Company Name: ${credentials.company_name},
Message : ${credentials.message}
          `,
        })
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.setState({resultMsg: {status: ''}, formSuccess: true, loading: false});
                }, 500);
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}, loading: false});
          });
      });
    }
  };

  render = () => {

    const {loading, error, resultMsg, formSuccess} = this.state;

    const initialValues = {
      'name': '',
      'email': '',
      'phone': '',
      'message': '',
      'recaptcha': '',
      'partner_type': '',
      'company_name': '',
    };

    const formValidation = Yup.object().shape({
      name: Yup.string().min(2, 'Too Short!').required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
      phone: Yup.number().required('Required'),
      code: Yup.string().required('Required'),
      company_name: Yup.string().required('Required'),
      partner_type: Yup.string().required('Required'),
      message: Yup.string().min(50, 'Too Short Message').required('Required'),
      recaptcha: Yup.string().required('Required'),
    });


    if (error) return <Error text={error}/>;

    return (
      <div className="index screen-container">
        <Helmet>
          <title>Partner with Renzo</title>
        </Helmet>

        <div className={'main-container container'}>
          <div className={'row justify-content-md-center'}>
            <div className={'col-sm-12 col-lg-8 col-md-10'}>

            </div>
          </div>

          <div className={'row justify-content-md-center'}>
            <div className={'col-sm-12 col-lg-12'}>
              <h3>Partner with Renzo</h3>
              <p className={'mb-5'}>Renzo supercharges hotel operations with Renzo Hotel OS making them more efficient.
                Join an elite network of Renzo partners to build parallel growth avenues for your business and deliver
                more value to our shared customers.</p>

              <div className={'row'}>
                <div className={'col-sm-12 col-md-6'}>
                  <h5>Reseller Partners</h5>
                  <p>Grow your business by providing exceptional hotel OS to your clients. Get access to training,
                    resources and dedicated marketing experts to grow your revenues.</p>

                  <h5>Technology Partners</h5>
                  <p>Integrate your product with Renzo. Create a seamless connection between our platform and yours to
                    enhance our collective offering, providing more value to clients.</p>
                </div>

                <div className={'col-sm-12 col-md-6 border-left'}>
                  {formSuccess ?
                    <div>
                      <div className={'p-3 mt-3 mb-0 text-center'}>
                        <div className={'col-md-12 p-lg-2'}>
                          <h1><span className={'fas fa-check-circle green-cl'}/> Thank you. </h1>
                          <p>We have received your request.</p>
                          <p>One of our representatives will contact you shortly</p>
                        </div>
                      </div>
                    </div> :
                    <Formik
                      initialValues={initialValues}
                      validationSchema={formValidation}
                      onSubmit={values => this.sendMessage(values)}
                    >
                      {({errors, touched, setFieldValue}) => (
                        <form>
                          <Alerts
                            status={resultMsg.status}
                            success={resultMsg.success}
                            error={resultMsg.error}
                          />
                          <div className={'row'}>
                            <div className={'col-sm-12'}>
                              <div className="form-row mb-2">
                                <div className="col-12 form-group">
                                  <Input
                                    required
                                    name='name'
                                    label={'Name'}
                                    className={'form-control'}
                                  />
                                </div>
                              </div>

                              <div className="form-row mb-2">
                                <div className="col-6 form-group">
                                  <Input
                                    required
                                    name='company_name'
                                    label={'Company Name'}
                                    className={'form-control'}
                                  />
                                </div>
                                <div className="col-6 form-group">
                                  <Select
                                    required
                                    className={'form-control rounded-right-0'}
                                    name='partner_type'
                                    label={'Partner Type'}
                                    placeholder='Select an Option'
                                    options={Constants.PARTNER_TYPE}
                                  />
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="col-lg-6 col-md-6 form-group">
                                  <Input
                                    required
                                    name='email'
                                    type={'email'}
                                    label={'E-mail'}
                                    className={'form-control'}
                                  />
                                </div>
                                <div className="col-lg-6 col-md-6 ">
                                  <div className={'row'}>
                                    <div className={'col-4 pr-0 form-group'}>
                                      <Select
                                        className={'form-control rounded-right-0'}
                                        name='code'
                                        label={'Country'}
                                        placeholder='Select an Option'
                                        options={SelectList.phoneCountryCode()}
                                      />
                                    </div>
                                    <div className={'col-8 pl-0 form-group'}>
                                      <Input
                                        required
                                        type={'tel'}
                                        name='phone'
                                        label={'Phone'}
                                        className={'form-control rounded-left-0'}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="form-row mb-2">
                                <div className="col-12 form-group">
                                  <Textarea
                                    required
                                    name='message'
                                    label={'Message'}
                                    className={'form-control'}
                                  />
                                </div>
                              </div>


                              <div className="form-row">
                                <div className="col-12 form-group">
                                  <div className={'form-element input'}>
                                    <span className={'label'}>Recaptcha Validation</span>
                                    <Reaptcha
                                      sitekey={AppConfig.reCaptchaKey}
                                      onVerify={(data) => setFieldValue('recaptcha', data)}
                                      onExpire={() => setFieldValue('recaptcha', '')}
                                    />
                                    {errors.recaptcha
                                     && touched.recaptcha && (
                                      <span className={'error'}>{errors.recaptcha}</span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="col-12 form-group">
                                  <p className={'small text-muted'}>
                                    By submitting, you agree to our
                                    <a
                                      rel='noopener noreferrer'
                                      target={'_blank'} href={'https://renzo.in/policies/'}>
                                      Terms of service & Privacy Policy
                                    </a>.
                                  </p>
                                </div>
                                <div className="col-12 form-group">
                                  {loading ? <Loading heightMatch={false}/>
                                    :
                                    <SubmitBtn className={'btn btn-success btn-block btn-lg'}>
                                      Submit
                                    </SubmitBtn>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      formSuccess: false,
    };
  }

}

export default Partners;