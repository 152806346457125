/**
 *
 * Features Point of Sales
 * *
 */

import React, {Component} from 'react';
import Helmet from 'react-helmet';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import ReEngage1 from '../../components/ui/ReEngage1';
import {Link} from 'react-router-dom';

/* Component ==================================================================== */
class Index extends Component {

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    return (
      <div className="index screen-container">
        <Helmet>
          <title>Point of Sales</title>
        </Helmet>

        <div className={'p-3 mt-3 mb-0 text-center'}>
          <div className={'col-md-12 p-lg-2'}>
            <h1>Point of Sale Simplified</h1>
            <p className={'lead'}>Manage Restaurants, Bar, SPA, Travel Desk and more.</p>
            <div className={'spacer-20'}/>

            <p className={'small'}>How it works <br/>
              <i className="fa fa-arrow-down"/>
            </p>
          </div>
        </div>

        {/*<div className={'mx-auto bg-image'}><img alt={'Renzo'} src={require('../../images/resource/main_bg.svg')}/>*/}
        {/*</div>*/}


        <div className={'container'}>


          <div className="d-md-flex flex-md-equal w-150 p-md-3 border-top position-relative center-line">
            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/pos/Order-Create.png')}/>
                </div>
              </div>

              <h2>Create Order</h2>
              <p>Create order by entering the initial order items from the Point of Sale Menu.</p>

              <p>To create an order you need to enter</p>
              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Product(s) and its Quantity</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Allot a Table ( if applicable )</h6>
                </li>
              </ul>
            </div>
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/pos/Order-Create.png')}/>
              </div>
            </div>
          </div>

          <div className="d-md-flex flex-md-equal w-150  p-md-3 position-relative center-line">
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/pos/Order-Manage.png')}/>
              </div>
            </div>

            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center right-side-box">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/pos/Order-Manage.png')}/>
                </div>
              </div>

              <h2>Manage Order</h2>
              <p>Changes like add or remove item(s), change quantity can be made; discounts or offers can be added.</p>

              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Add or Remove Item(s)</h6>
                </li>


                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Add customer tax number</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Discount for individual item or entire order.</h6>
                </li>


              </ul>
            </div>
          </div>

          <div className="d-md-flex flex-md-equal w-150 p-md-3 position-relative center-line">
            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/pos/Finalised-Order.png')}/>
                </div>
              </div>

              <h2>Finalize Order</h2>
              <p>Once changes are confirmed you can finalize the order and perform either of the actions </p>

              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Add Order to an existing booking.</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Add Payment to order directly.</h6>
                </li>


              </ul>
            </div>
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/pos/Finalised-Order.png')}/>
              </div>
            </div>
          </div>

          <div className="d-md-flex flex-md-equal w-150  p-md-3 position-relative center-line">
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/pos/Link-Order.png')}/>
              </div>
            </div>

            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center right-side-box">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/pos/Link-Order.png')}/>
                </div>
              </div>

              <h2>Link to Booking</h2>
              <p>Finalized orders can be linked to an active booking for ease of management.</p>

              <p>System displays the active rooms, you can confirm it with guest name / signature and link to the booking
                instantly.</p>
            </div>
          </div>

          <div className="d-md-flex flex-md-equal w-150 p-md-3 position-relative center-line">
            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/pos/Order-Payment.png')}/>
                </div>
              </div>

              <h2>Order Payment</h2>
              <p>Close the order by adding a payment mode to it. If linked to a booking the payment can be settled at
                the time of checkout.</p>

              <p>Choose a modes of payments for the order.</p>
              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Cash</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Cheque or  Bank Transfer</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Point of Sales Terminal</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Online Payment</h6>
                </li>
              </ul>

              <div className={'spacer-20'}/>
              <Link
                to={'/features/Payments'}
                className={'btn btn-success'}>Learn More on Payment Management System <i className="fa fa-arrow-right"/></Link>

            </div>
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/pos/Order-Payment.png')}/>
              </div>
            </div>
          </div>


          <ReEngage1/>

        </div>
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false
    };
  }
}

export default Index;