import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AppConfig from  './constants/config';
import Main from './containers/index';
import ScrollToTop from './components/decorators/ScrollToTop';

const navigator = () => (
  <div>
    {(AppConfig.environment !== 'production') &&
     <div className="header sticky-top mb-0">
       <div className="alert alert-warning alert-dismissible fade show mb-0" role="alert">
         <strong>Warning: </strong> This is a staging site, connected to end point : {AppConfig.endpoint}.
         The production site is <a href={AppConfig.hostname}>here</a>.
         <button type="button" className="close" data-dismiss="alert" aria-label="Close">
           <span aria-hidden="true">&times;</span>
         </button>
       </div>
     </div>
    }
    <Switch>
      <ScrollToTop>
        <Route path="/" component={Main}/>
      </ScrollToTop>
    </Switch>
  </div>
);

export default navigator;