/**
 *
 * Pricing Page
 * pricing and plan info
 */

import React, {Component} from 'react';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';

// Config
import {AppConfig} from '../constants';


// Consts and Libs
import AppAPI from '../lib/api';

// Components
import Error from '../components/general/Error';

/* Component ==================================================================== */
class Pricing extends Component {

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {

    AppAPI.billingapi.get('plans-public/')
      .then((res) => {
        this.setState({
          loading: false,
          plans: res.results
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          resultMsg: {error}
        });
      });
  };

  managePriceType = (priceType) => {
    if (priceType === null) {
      priceType = 'month';
    }
    this.setState({priceType: priceType});
  };
  render = () => {

    const {loading, error, plans, priceType} = this.state;

    if (error) return <Error text={error}/>;

    return (
      <div className="index screen-container">
        <Helmet>
          <title>Pricing</title>
        </Helmet>

        <div className={'container'}>
          <div className={'row text-center'}>
            <div className={'col-xl-12 col-lg-12 col-md-12'}>
              <h1>Plans for usage.</h1>
              <p className={'lead'}>Get the Power, Control and Customization you need to manage your Property.</p>
              <p className={'lead small'}>Simple, Straightforward Pricing and No Setup Costs.</p>
            </div>
          </div>
          <div className={'spacer-20'}/>
          <div className={'text-center'}>
            <div className={'btn-group'} role="group" aria-label="Basic example">
              <button
                onClick={() => this.managePriceType('month')}
                className={'a btn btn-lg ' + (priceType === 'month' ? 'btn-success' : 'btn-outline-success')}
              >Monthly
              </button>
              <button
                onClick={() => this.managePriceType('year')}
                className={'a btn ' + (priceType === 'year' ? 'btn-success' : 'btn-outline-success')}
              >Annually <br/>
                <small>20% discount</small>
              </button>
            </div>
          </div>
          <div className={'spacer-20'}/>
          <div className={'p-md-3'}>

            {(plans.length > 0 && !loading) ?
              <div className={'card-deck mb-3'}>
                {plans.map((data, i) => (
                  <div key={i} className={'card mb-4 box-shadow '}>
                    <div className={'card-body'}>
                      <div className={'text-center'}>
                        <h6 className={'text-uppercase'}>{data.name}</h6>
                        <h1
                          className={'display-4 d-inline-block'}>{priceType === 'month' ? data.price_30 : data.price_365} </h1>
                        <p className={'d-inline-block'}>{data.currency}
                          <small className={'text-muted'}>/{priceType}</small>
                        </p>
                      </div>
                      <hr/>
                      <a
                        href={AppConfig.dashboardHostname + '/auth/register'}
                        className={'btn btn-lg btn-block ' + (data.featured ? 'btn-success' : 'btn-outline-success')}>Start
                        Trial
                      </a>
                      <div className={'my-5'}>
                        {data.previous_plan_name &&
                         <div>
                           <h4 className={'text-center mb-1 h4'}>{data.previous_plan_name}</h4>
                           <p className={'text-center'}>+</p>
                         </div>
                        }
                        {(data.feature_list && data.feature_list.feature_list) &&
                         <ul className={'list-unstyled'}>
                           {data.feature_list.feature_list.map((feature, i) => (feature !== '' ?
                             <li key={i}>
                               <p className={'text-capitalize'}>
                                 <span
                                   className={'far fa-dot-circle mr-2 ' + (data.featured ? 'green-cl' : 'grey-dark-cl')}/>{feature}
                               </p>
                             </li>
                             : <li><p>&nbsp;</p></li>
                           )
                           )}
                         </ul>
                        }
                      </div>
                      <a
                        href={AppConfig.dashboardHostname + '/auth/register'}
                        className={'btn btn-lg btn-block ' + (data.featured ? 'btn-success' : 'btn-outline-success')}>Start
                        Trial
                      </a>
                    </div>
                  </div>)
                )}
              </div>
              :
              <div className={'p-3 mt-3 mb-0 text-center'}>
                <div className={'col-md-12 p-lg-2'}>
                  <h1><span className={'fas fa-sync green-cl'}/> Loading... </h1>
                  <p>Loading price for your region.</p>
                </div>
              </div>
            }
          </div>
          <div className={'spacer-20'}/>
          <div className={'row'}>
            <div className={'col-lg-6 col-md-10 col-sm-12 mx-auto border p-3'}>
              <h6 className={'text-uppercase text-center'}>Enterprise</h6>
              <hr/>
              <div className='row mt-5'>
                <div className={'col-md-6 col-sm-12'}>
                  <h4 className={'text-center mb-1 h4'}>Professional</h4>
                  <p className={'text-center'}>+</p>
                  <ul className={'list-unstyled mx-auto'}>
                    <li>
                      <p className={'text-capitalize'}>
                        <span className={'far fa-dot-circle mr-2 green-cl'}/> Unlimited Users
                      </p>
                    </li>
                    <li>
                      <p className={'text-capitalize'}>
                        <span className={'far fa-dot-circle mr-2 green-cl'}/> Unlimited Records
                      </p>
                    </li>
                  </ul>
                </div>
                <div className={'col-md-6 col-sm-12'}>
                  <div className={'site-wrapper'}>
                    <div className={'site-wrapper-inner'}>
                      <div className={'cover-container'}>
                        <Link to={'/contact'} className={'btn btn-lg btn-block btn-success'}>
                          Contact Sales
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'spacer-20'}/>
        </div>
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      plans: [
        {
          name: 'Starter',
          price_30: 3500,
          price_365: 35000,
          currency: 'INR',
          feature_list: [
            '1 user',
            'Booking Engine',
            'Front Desk',
            'Mass email-1k',
            '5000 records',
            '2 POS',
            'Review Management',
            'Mobile app-based point of sale',
            '', '', '', ''
          ]
        },
        {
          name: 'Standard',
          price_30: 14000,
          price_365: 140000,
          currency: 'INR',
          previous_plan_name: 'Starter',
          feature_list: [
            'Upto 5 user',
            'User Roles & Profiles',
            'Email support',
            'Phone Support(150 mins)',
            '15000 records',
            '5 POS',
            'Advance Analytics',
            'Mass Email-50k',
            'Setup & training Support',
            'Restaurant Management'
          ],
          featured: true
        },
        {
          name: 'Professional',
          price_30: 42000,
          price_365: 420000,
          currency: 'INR',
          previous_plan_name: 'Standard',
          feature_list: [
            'Upto 20 user',
            'Dedicated account Manager',
            'GDS',
            'Phone Support-Unlimited',
            '50000 records',
            'Live Chat Integration with website',
            'Unlimited POS',
            'Multiple Currency support',
            'Mass Email-2L',
            'Multi Hotel Setup'
          ]
        }
      ],
      priceType: 'month'
    };
  }
}

export default Pricing;