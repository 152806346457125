/**
 * App Error Messages
 *
 */

export default {
  // Defaults
  default: 'Hmm, an unknown error occurred',
  notfound: 'The link your are trying to access is not valid.',
};
