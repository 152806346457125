/**
 *
 * Terms and Conditions
 *
 */

import React, {Component} from 'react';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';

/* Component ==================================================================== */
class Terms extends Component {

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    /*<p>“www.renzo.in” (hereinafter, the ‘Website’) is owned and operated by Renzo
     Solutions Pvt. Ltd., (hereinafter the ‘Company’), a private limited company
     formed as per the laws of India having its registered office at C/201, Ajit
     Nagar, JB Nagar, Andheri East,
     Mumbai - 400059.</p>*/

    return (
      <div className="index screen-container">
        <Helmet>
          <title>Terms of Service</title>
        </Helmet>

        <div className={'main-container container'}>
          <div className={'row justify-content-md-center'}>
            <div className={'col-sm-12 col-lg-8 col-md-10'}>
              <h1 className={'text-center'}>Terms of Service</h1>
            </div>
          </div>
          <div className={'spacer-20'}/>
          <div className={'row justify-content-md-center'}>
            <div className={'col-sm-12 col-lg-8 col-md-10'}>
              <ol className={'pl-0'}>
                <li>
                  <strong>GENERAL</strong>

                  {/*<p>“www.renzo.in” (hereinafter, the ‘Website’) is owned and operated by Renzo Solutions Inc,*/}
                  {/*  (hereinafter the ‘Company’), a company, having its office at 2035 Sunset Lake Road, Newark 19702,*/}
                  {/*  USA</p>*/}

                  <p>“www.renzo.in” (hereinafter, the ‘Website’) is owned and operated by Renzo
                  Solutions Pvt. Ltd., (hereinafter the ‘Company’), a private limited company
                  formed as per the laws of India having its registered office at C/201, Ajit
                  Nagar, JB Nagar, Andheri East,
                  Mumbai - 400059.</p>

                  <p><i>You are advised to read and understand these Terms carefully as moving past
                    the
                    home page, or using any of the services shall be taken to mean that You have
                    read and agreed to all of the policies, which constitute a legally binding
                    agreement between you and the Company. These terms are to be read along with the
                    Privacy Policy and any other policies on the Website.</i></p>

                  <p><i>This document is an electronic record under the laws of India which require
                    publishing of Rules and Regulations, Privacy Policy and Terms of Service for
                    access or usage of the Website and being generated by a computer system, it does
                    not require any physical or digital signatures.</i></p>

                </li>

                <li>
                  <stong>DEFINITIONS</stong>
                  <ol>
                    <li>“<strong>We</strong>”, “<strong>Us</strong>”, “<strong>Our</strong>”, shall
                      mean the Company.
                    </li>
                    <li>“<strong>Terms</strong>”, “<strong>Terms and conditions</strong>”,
                      “<strong>Policy</strong>”, “<strong>T&C</strong>” shall mean the entire
                      Agreement and all parallel policies that you agree to in mutual agreement by
                      using our Website.
                    </li>
                    <li>“<strong>You</strong>”, “<strong>Your</strong>”, “<strong>User</strong>”
                      refers to the user of the site. “User” is anyone who
                      is visiting, accessing or browsing the Website (visitor), or any person who
                      has registered for a user profile on the Website (Registered User).
                    </li>
                    <li>“<strong>Content</strong>” refers to any or all forms of data which are made
                      available on the
                      Website.
                    </li>
                    <li>“<strong>Third Party</strong>” refers to any person other than the “User”,
                      the “Company”, or
                      the “Website”.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>ABOUT THE WEBSITE</strong>
                  <p>The Website is a cloud based property management system which provides tools for
                    small to mid-size properties/hotels to manage their business including but not
                    limited to guest bookings, customer logs, customer data, housekeeping, accounts,
                    payments, POS, sales and data (hereinafter referred to as the “Services”). The
                    Services offered by the Website are payable by You. The Website grants you the
                    right
                    to access and use the Services according to the plan you subscribe for. The
                    Website
                    helps you increase your property’s efficiency through a user-friendly
                    interface.</p>

                  <p>You agree and understand that if We disable access to Your account or login rout,
                    You may be prevented from accessing Your account details, data or other content
                    which is contained in Your account.</p>

                  <p>You acknowledge and agree that while We may not currently limit Your use of the
                    Website, We may do so if that use hinders Our ability to carry on operations. We
                    may
                    remove any content posted by You on the Website, if We believe that such content
                    is
                    in violation of this Agreement and is harmful or shall prejudice the use of the
                    Website by other Users. You understand that We shall not be responsible for any
                    loss
                    caused by modification of the features, limitation of use of the services or the
                    discontinuation of service altogether.</p>

                </li>

                <li>
                  <strong>ELIGIBILITY AND ACCESS</strong>
                  <ol>
                    <li>
                      <strong>For Visitor:</strong>
                      <p>Users are not required to register for browsing the Website. However, if
                        the
                        User intends to avail any Services offered by the Company, the User is
                        mandatorily required to register on the Website.</p>

                    </li>

                    <li>
                      <strong>For a Registered User:</strong>
                      <p>Users must register in order to avail the Services offered by the Company
                        on the Website by providing certain information, which includes, but is
                        not limited to, the following:</p>
                      <ul>
                        <li>Name</li>
                        <li>Phone</li>
                        <li>Email</li>
                      </ul>

                      <p>
                        At any time during your use of this Website including but not limited to
                        the time of registration, You are solely responsible for protecting the
                        confidentiality of Your username, password, enrolment no. and any
                        activity under your registered account shall be deemed to have been done
                        by You. In the case that You provide Us with false and/or inaccurate
                        details or the Company has reasons to believe You have done so, We hold
                        the right to permanently suspend Your account.
                      </p>

                      <p>In addition to this, You would be required to comply with the
                        following:</p>

                      <ul>
                        <li>You will not provide any false personal information.</li>
                        <li>You will not create/register an account for anyone other than
                          yourself without permission.
                        </li>
                        <li>You will not create more than one registered account/user profile.
                        </li>
                        <li>If your registered account is suspended/disabled by Us, You will not
                          create another account/user profile without our prior permission.
                        </li>
                        <li>You will not use our Website if you are under the age of 18 years.
                        </li>
                        <li>You will not share your registered account or let anyone else access
                          your account, or do anything else that might jeopardize the security
                          of your account.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>PRIVACY POLICY</strong>
                  <p>The Privacy Policy is separately available on the Website, and can be perused by
                    you at your convenience.</p>
                </li>
                <li>
                  <strong>CONTENT ON THE WEBSITE</strong>
                  <p>The User is solely responsible for the integrity, authenticity, quality and
                    genuineness of the information/content provided/written by the User on the
                    Website, the Company bears no liability whatsoever for any information on the
                    Website by the User. Further, the Website reserves its right to suspend the
                    account of any User for an indefinite period, or to terminate the account of any
                    User who is found to have created/shared/submitted/published any
                    information/Content or part thereof that is found to be
                    untrue/inaccurate/misleading. The User shall be solely responsible for making
                    good any financial or legal losses incurred by the Company through the
                    creation/sharing/submission/publishing of information/Content or part thereof
                    that is deemed to be untrue/inaccurate/misleading. The Users shall not copy,
                    adapt, and modify any content without written permission of the Company.</p>
                </li>

                <li>
                  <strong>YOUR PASSWORD AND ACCOUNT SECURITY</strong>
                  <p>You agree and understand that You are solely responsible to the Website and to
                    third parties for (a) maintaining the confidentiality of passwords associated
                    with any account You use to avail Our services and (b) any use of the Website
                    with the username and password as created by You.</p>

                  <p>You agree to ensure that Your exit from Your account at the end of each session
                    and that the activities from Your account shall be treated as carried out by
                    You. You acknowledge and agree that You will be solely responsible for all
                    activities that occur under Your account. If You post a hyperlink of Your
                    content available on Our Website, either on Your own or other third party
                    Website and applications, or You share such content with any person and they
                    post Your content on other Website and applications or forward it to other
                    individuals, then We will not be liable in any way for any loss or damage
                    arising from the misuse or publication of such content by others.</p>

                  <p>We will not and cannot be held liable for any loss or damage arising from Your
                    failure to comply with the conditions set out in this Agreement.</p>
                </li>

                <li>
                  <strong>CHARGES</strong>
                  <p>The use of this Website by the User, including browsing the Website is free of
                    cost. The Customers are only required to pay for the subscribing to the Services
                    which they wish to purchase from the Website. The price of the Services listed
                    on the Website shall be inclusive of all taxes. All prices listed on the Website
                    are in Indian Rupees.</p>

                  <p>We reserve the right to amend the fee policy and charge Users for the use of the
                    Website and for availing its Services. In the event of such change in policy,
                    Users shall be intimated of the same via email/telephone and such change shall
                    be effective as soon as Users have been intimated. Subsequent to such change, it
                    is the choice of the User whether or not to continue with the Services offered
                    by Us.</p>

                </li>

                <li>
                  <strong>PAYMENT</strong>
                  <p>To process financial transactions on the Website we use Third Party payment
                    gateway “paytm” to handle the online financial transactions of the Website. We
                    are not responsible for these third party services. Such services are dependent
                    on the respective Third Party’s legal terms. The billing details provided during
                    payment should be up-to- date, such as the billing address and the user’s name.
                    Payment information on the Website of Users using our services is stored on the
                    database for the respective website as encrypted using basic encryption which
                    can be decrypted to reveal the original information. This information is
                    provided to us by the third party payment gateway services we use to provide
                    online payment functionality, and hence, may contain certain personal
                    information.</p>

                  <p>We are not responsible for what information is sent to us and stored as this is
                    sent to us by a third party and we cannot control the information sent. However,
                    storage of this information is necessary for legalities and auditing as well as
                    for verification and more. Currently, the information stored that may be deemed
                    personal to the user is their first name, last name and their address zip code.
                    The Website also offers Cash on delivery for payment.</p>
                </li>

                <li>
                  <strong>THIRD PARTY CONTENTS/ADS</strong>
                  <p>Our Website will incorporate information and ads from third party websites and
                    applications including but not limited to various payment gateways, which will
                    be incorporated on the Website from time to time. Our Website merely displays
                    such information and is not responsible for the authenticity or content of such
                    information from third party websites and applications.</p>

                  <p>The Company does not contact the User’s customers for marketing purposes or sell
                    or rent or lease the User’s guest information to third parties. We may share the
                    User’s customer information such as contact information, credit card numbers and
                    other non-personally identifiable with trusted partners to help us perform and
                    deliver the Services of the Website. All such third parties are prohibited from
                    using your personal information except to provide these services to the Company
                    and they are required to maintain the confidentiality of your information.</p>

                  <p>We do not disclose or use any sensitive personal information, such as race,
                    religion or political affiliation, without your explicit consent.</p>

                </li>

                <li>
                  <strong>USER’S OBLIGATIONS</strong>
                  <p>The User undertakes to fulfill the following obligations and failure to satisfy
                    any of these obligations gives Us the right to permanently suspend Your user
                    profile and/or claim damages for any losses that accrue to Us or additional
                    costs that may be imposed on us.</p>
                  <ol>
                    <li>You agree to comply with all local laws and regulations governing the use of
                      the Website and its services, including, without limitation, any usage rules
                      set forth in this Terms of Service.
                    </li>
                    <li>You understand and agree that You will be liable for any damage or loss
                      caused by
                      Your actions on the Website and that such damage or loss will also be borne
                      by You. The Company shall in no what whatsoever be held responsible for any
                      violations caused by you.
                    </li>
                    <li>
                      You undertake not to:
                      <ul>
                        <li>Cut, copy, distribute, modify, recreate, post, publish or create
                          derivative works from, transfer, or sell any information or content
                          obtained from the Website. For the removal of doubt, it is clarified
                          that unlimited or wholesale reproduction, copying of the content for
                          commercial or non-commercial purposes and unwarranted modification
                          of data and information within the content of the Website is not
                          permitted. Should you want to engage in one or more such actions,
                          prior permission from Us must be obtained.
                        </li>
                        <li>Access (or attempt to access) the Website and/or the Contents or
                          Services offered on the Website by any means other than through the
                          interface that is provided by the Website. The use of deep-link,
                          robot, spider or other automatic device, program, algorithm or
                          methodology, or any similar or equivalent manual process, to access,
                          acquire, copy or monitor any portion of the Website or Content, or
                          to obtain or attempt to obtain any materials, documents or
                          information, or contents through any means not specifically made
                          available through the Website is prohibited. You acknowledge and
                          agree that by accessing or using the Website, You may be exposed to
                          content from other Users or Third Parties Ads that you may consider
                          offensive, indecent or otherwise objectionable. We disclaim all
                          liabilities arising in relation to such offensive content on the
                          Website. Further, You may report against such offensive contents to
                          Us.
                        </li>
                        <li>Use the Website in any manner that may impair, overburden, damage,
                          disable or otherwise compromise (i) the Company’s services; (ii) any
                          other party's use and enjoyment of the Company’s services; or (iii)
                          the contents of any Third Party.
                        </li>


                        <li>Use the Company’s Services for any unlawful purposes or to conduct
                          any unlawful activity, including, but not limited to, fraud,
                          embezzlement, money laundering or identity theft.
                        </li>
                        <li>Abuse, harass, threaten, defame, disillusion, erode, abrogate,
                          demean or otherwise violate the legal rights of others.
                        </li>
                        <li>Engage in any activity that interferes with or disrupts access to
                          the Website or the Company’s Services (or the servers and networks
                          which are connected to the Website).
                        </li>
                        <li>Upload or distribute files and contents that contain viruses,
                          corrupted files, or any other similar software or programs that may
                          damage the operation of the Website.
                        </li>
                        <li>Download any file or content posted in the Website that you know, or
                          reasonably should know, cannot be legally distributed in such
                          manner.
                        </li>
                        <li>Probe, scan or test the vulnerability of the Website, or any network
                          connected to the Website, nor breach the security or authentication
                          measures on the Website, or any network connected to the Website.
                          You may not reverse look-up, trace or seek to trace any information
                          on any other user, or visitor of the Website, or exploit the Website
                          or information or contents made available or offered by or through
                          the Website, in any way whether or not the purpose is to reveal any
                          information, including but not limited to personal identification
                          information, other than Your own information, as provided for by the
                          Website.
                        </li>
                        <li>Disrupt or interfere with the security of, or otherwise cause harm
                          to, the Website, systems resources, servers or networks connected to
                          or accessible through the Website, or any affiliated or linked
                          Websites.
                        </li>
                        <li>Use the Website, or any material or Content for any purpose that is
                          unlawful or prohibited by these Terms of Service, or to solicit the
                          performance of any illegal activity or other activity which
                          infringes the our rights, or the rights of other Third Parties.
                        </li>
                        <li>Violate any applicable laws or regulations for the time being in
                          force within or outside India.
                        </li>
                        <li>Violate any code of conduct or other guidelines, which may be
                          applicable for or to any particular Service provided by Us.
                        </li>
                        <li>Threaten the unity, integrity, defense, security or sovereignty of
                          India, friendly relations with foreign states, or public order or
                          causes incitement to the commission of any cognizable offence or
                          prevents investigation of any offence or is insulting any other
                          nation.
                        </li>
                        <li>Disseminate information through the Website that is false,
                          inaccurate or misleading, or violate any applicable laws or
                          regulations for the time being in force in or outside India.
                        </li>
                      </ul>

                      <p>The User hereby expressly authorizes the Company/Website to disclose any
                        and all information relating to the User in the possession of the
                        Company/Website to law enforcement or other government officials, as the
                        Company may in its sole discretion, believe necessary or appropriate in
                        connection with the investigation and/or resolution of possible crimes,
                        especially those involve personal injury and theft/infringement of
                        intellectual property. The User further understands that the
                        Company/Website might be directed to disclose any information (including
                        the identity of persons providing information or materials on the
                        Website) as necessary to satisfy any judicial Order, law, regulation or
                        valid governmental request.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>SUSPENSION OF USER/BUSINESS USER ACCESS AND ACTIVITY</strong>
                  <p>Notwithstanding other legal remedies that may be available to it, the Company may
                    in its sole discretion limit the User’s access and/or activity by immediately
                    removing the User’s access credentials either temporarily or indefinitely, or
                    suspend/terminate the User’s association with the Website, and/or refuse to
                    usage of the Website to the User, without being required to provide the User
                    with notice or cause:
                  </p>

                  <ol>
                    <li>If the User is in breach any of these Terms or the Policy;</li>
                    <li>If the User has provided wrong, inaccurate, incomplete or incorrect
                      information;
                    </li>
                    <li>If the User’s actions may cause any harm, damage or loss to the other Users
                      or to the Company, at the sole discretion of the Company
                    </li>
                    <li>The Company shall not encourage any union or any 3rd party involvement in
                      the services provided on the Website, and if it is seen that some 3rd party
                      is getting involved, the Company holds the right to cancel the particular
                      company business plan or registered user’s funds.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>COMMUNICATION</strong>
                  <p>By using this Website, and providing his/her identity and contact information to
                    the Company through the Website, the User hereby agrees and consents to
                    receiving calls, auto-dialed and/or pre-recorded message calls, e-mails, and
                    SMSs from the Company and/or any of its affiliates or partners at any time,
                    subject to the Privacy Policy.
                    The User expressly agrees that notwithstanding anything contained hereinabove,
                    it may be contacted by the Company or any of its affiliates/partners relating to
                    any service availed of by the User on the Website or anything pursuant thereto.
                    It is expressly agreed to by the User that any information shared by You with
                    the Company shall be governed by the Privacy Policy.</p>

                </li>
                <li>
                  <strong>DATA STORAGE AND SECURITY</strong>
                  <p>We provide utmost data security and protection through reasonable securities and
                    practices as defined by law from time to time. We provide adequate date security
                    for the information collected by us. For further information on privacy and
                    security of data please refer to the Privacy Policy on the Website.</p>
                </li>

                <li>
                  <strong>TERM AND TERMINATION</strong>
                  <ul>
                    <li>These Terms will remain in full force and effect while you are a User of the
                      Website.
                    </li>
                    <li>In the event the User fails to pay the renewal fee post 1 (one) year and 30
                      days, the Company hold the rights to terminate the agreement and
                      suspend/cancel the registered user’s account.
                    </li>
                    <li>The Website may at any time by giving you reasonable notice, terminate the
                      agreement with you, deny you access to the Website and/or suspend or delete
                      your User Profile and/or any content you have submitted on the Website.
                    </li>
                    <li>The Website may immediately terminate the agreement with you, deny you
                      access to the Website and delete your User Profile in the event that you
                      have breached any of these Terms of Service.
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>REFUND POLICY</strong>
                  <p>The registration fee paid on the Website is Non-Refundable. However,
                    the detailed <Link to={'/policies/refund-and-cancellation'}> Refund Policy</Link> is separately
                    available on the
                    Website, and can be perused by you at your convenience.</p>
                </li>

                <li>
                  <strong>INTELLECTUAL PROPERTY</strong>
                  <p>We place great emphasis on intellectual property. We own the trademarks and the
                    user interface of our Website are all proprietary and the copyright belongs to
                    us. All third party information and trademarks displayed on our Website is
                    protected under the laws relating to copyrights, trade dress, trademarks and is
                    owned by the respective third party. Unless otherwise expressly mentioned, all
                    other content on our Website shall be reserved and use of any such intellectual
                    property without prior consent or permission from the respected owners will
                    amount to infringement will be subject to legal action by owners of such
                    intellectual property.</p>

                  <p>We are not liable for any reviews, comments and feedbacks made on third party
                    applications which are displayed on our Website, which are illegal, obscene,
                    threatening, defamatory, invasive of privacy, infringing on intellectual
                    property rights, or otherwise injurious to third parties.</p>

                  <p>You may not use any of the intellectual property displayed on the Website in any
                    manner that is likely to cause confusion among existing or prospective User of
                    the Website, or that in any manner disparages or discredits the Company/Website,
                    to be determined in the sole discretion of the Company.</p>
                  <p>You are aware all intellectual property, including but not limited to copyrights,
                    relating to said services resides with the Company, and that at no point does
                    any such intellectual property stand transferred to You.</p>

                  <p>You are further aware that any reproduction or infringement of the intellectual
                    property of the aforementioned artisans by You will result in legal action being
                    initiated against you by the respective artisans. It is agreed by You that the
                    contents of this Section shall survive even after the termination or expiry of
                    the Terms and/or Policy.</p>

                </li>

                <li>
                  <strong>CONFIDENTIAL INFORMATION</strong>
                  <p>“Confidential Information” shall mean and include any and all information and
                    content whether commercial or technical including the confidential and
                    proprietary information, technology know-how, commercialization strategies,
                    marketing strategies and/or any other proprietary information. We undertake not
                    to reveal to any third party the user’s confidential information. For further
                    information refer to the Privacy Policy here.</p>

                </li>
                <li>
                  <strong>DISCLAIMER OF WARRANTY</strong>
                  <p>You expressly understand and agree that, to the maximum extent permitted by
                    applicable law, the Website, services and other materials and contents provided
                    by this Website are on an "as is" basis without warranty of any kind, express,
                    implied, statutory or otherwise, including the implied warranties of title,
                    non-infringement, merchantability or fitness for a particular purpose. Without
                    limiting the foregoing, We make no warranty that:
                  </p>

                  <ol>
                    <li>Your requirements will be met or that services provided will be
                      uninterrupted, timely, secure or error-free;
                    </li>
                    <li>Materials, information and contents obtained will be effective, accurate or
                      reliable;
                    </li>
                    <li>Any wrongdoing by the User including theft, sexual abuse shall not be Our
                      responsibility;
                    </li>
                    <li>Any errors or defects in the Website, services or other contents will be
                      corrected to the maximum extent permitted by applicable law. We will have no
                      liability related to your content arising under intellectual property
                      rights, libel, privacy, publicity, obscenity or other laws. The User
                      understands and agrees that any material, content or data downloaded or
                      otherwise obtained through the Website is done entirely at his/her own
                      discretion and risk and he/she will be solely responsible for any damage to
                      his/her electronic systems or loss of data that results from the download of
                      such material, content or data. The Company accepts no liability for any
                      errors or omissions, with respect to any information provided to you whether
                      on behalf of itself or third parties.
                    </li>
                    <li>In the event of any issues with online transactions performed on the
                      Website, we shall not take up any liability and any transaction related
                      issues shall directly be dealt between the Users and the concerned payment
                      gateways and/or the concerned Banks.
                    </li>
                  </ol>
                  <p>We shall not be liable for any third party product or services. The advertisement
                    available on E-mail, or Website with respect to the third party website or the
                    products and services are for information purpose only.
                  </p>
                </li>

                <li>
                  <strong>LIMITATIONS OF LIABILITY</strong>
                  <p>The Services we provide are solely limited to the description provided in this
                    Terms of Service and the Website. We will not be liable for information and
                    errors from third party websites and applications.</p>
                  <p>The Company/Website is not responsible for any consequences arising out of the
                    following events:</p>

                  <ol>
                    <li>If the Website is inoperative/non-responsive due to any connectivity errors
                      associated with the internet connection such as but not limited to slow
                      connectivity, no connectivity, server failure.
                    </li>
                    <li>If the User has fed incorrect information or data or for any deletion of
                      data.
                    </li>
                    <li>If there is undue delay or inability to communicate through email.</li>
                    <li>If there is a failure in the functioning of any other service provided by
                      the Website.
                    </li>
                  </ol>


                  <p>The Website accepts no liability for any errors or omissions, whether on behalf
                    of itself or third parties, or for any damage caused to the User, the User’s
                    belongings, or any third party, resulting from the use or misuse of the Website
                    or any service availed of by the User through the Website.</p>

                  <p>Users may be held legally responsible for damages suffered by other Users, the
                    Website or any third party as a result of legally actionable or defamatory
                    comments, remarks, or other information or content posted to the Website.</p>
                  <p>Users are to comply with all laws applicable to them or to their activities, and
                    with all Policies, which are hereby incorporated into this Agreement by
                    reference.</p>
                  <p>The Website expressly excludes any liability for any loss or damage that was not
                    reasonably foreseeable by the Website and which is incurred by you in connection
                    with the Website, including loss of profits; and any loss or damage incurred by
                    you as a result of your breach of these terms.</p>
                  <p>To the fullest extent permitted by law, the Website shall not be liable to You or
                    any other party for any loss or damage, regardless of the form of action or
                    basis of any claim. You acknowledge and agree that your sole and exclusive
                    remedy for any dispute with us is to terminate your use of the Website.</p>

                </li>

                <li>
                  <strong>INDEMNIFICATION</strong>
                  <p>You agree to indemnify, defend and hold harmless the Company including but not
                    limited to its agents and employees from and against any and all losses,
                    liabilities, claims, damages, demands, costs and expenses (including legal fees
                    and disbursements in connection therewith and interest chargeable thereon)
                    asserted against or incurred by Us that arise out of, result from, or may be
                    payable by virtue of, any breach or non-performance of any representation,
                    warranty, covenant or agreement made or obligation to be performed by you
                    pursuant to these Terms of Service. Further, you agree to hold us harmless
                    against any claims made by any third party due to, or arising out of, or in
                    connection with, your use of the Website, any claim that your material/content
                    caused damage to a third party, your violation of the Terms of Service, or your
                    violation of any rights of another, including any intellectual property
                    rights.</p>

                  <p>In no event shall the Company, its officers, directors, employees, partners or
                    suppliers be liable to you or any third party for any special, incidental,
                    indirect, consequential or punitive damages whatsoever, including those
                    resulting from loss of use, data or profits, whether or not foreseeable or
                    whether or not we have been advised of the possibility of such damages, or based
                    on any theory of liability, including breach of contract or warranty, negligence
                    or other tortious action, or any other claim arising out of or in connection
                    with your use of or access to the Website, services, contents or materials.</p>
                  <p>The limitations and exclusions in this clause apply to the maximum extent
                    permitted by applicable law.</p>

                </li>

                <li>
                  <strong>GOVERNING LAW</strong>
                  <ol>
                    <li>
                      <strong>Arbitration:</strong>
                      <p>These Terms of Service including the privacy policy shall be governed by
                        Indian law.
                        Any disputes arising out of or in connection with these Terms of Service
                        shall be governed by the laws the Arbitration and Conciliation Act,
                        1996. The arbitration proceedings shall be in English language.
                      </p>
                    </li>
                    <li>
                      <strong>Applicable Law:</strong>
                      <p>You agree that the laws of the State of Maharashtra, India, excluding its
                        conflict of laws rules, shall exclusively govern any dispute relating to
                        this Agreement and/or the Services. We both agree that all of these
                        claims can only be litigated in the courts of Mumbai, Maharashtra and we
                        each agree to personal jurisdiction in those courts.
                      </p>
                    </li>
                    <li>
                      <strong>SEVERABILITY</strong>
                      <p>Any provision of these Terms of Service which is, or may be, void or
                        unenforceable shall, to the extent of such invalidity or
                        unenforceability, be deemed severable and shall not affect any other
                        provision of these terms and conditions.
                      </p>
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>MISCELLANEOUS TERMS</strong>
                  <ol>
                    <li>The Company is not an agent, employee or partner of the User or third
                      parties.
                    </li>
                    <li><strong>Modification of the Terms of Service:</strong> These Terms of
                      Service may be modified from time to time to deliver better services. It
                      shall be the obligation of the User to acquaint himself or herself with the
                      modified Terms of Service from going to the Website from time to time. The
                      Company agrees to inform the User either through email or a pop up on the
                      main page if there have been any significant changes made to the Terms of
                      Service on the Website.
                    </li>
                    <li><strong>Waiver:</strong> A decision not to act against any User for any
                      improper conduct does not prevent the Company from acting in any future case
                      against the same or other Users.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>CONTACT US</strong>
                  <p>If you have any questions about this Agreement, the practices of the Company, or
                    your experience with the Website, you can check contact section for email
                    address.</p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false
    };
  }

}

export default Terms;
