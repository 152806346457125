/**
 * Demo Scheduler
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import Reaptcha from 'reaptcha';
// Consts and Libs
import {Constants, SelectList, AppConfig} from '../constants';
// Components
import Alerts from '../components/ui/Alerts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import Loading from '../components/general/Loading';
// Form
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Input, Select, SubmitBtn} from 'react-formik-ui';
import AppAPI from '../lib/api';

/* Component ==================================================================== */

class DemoScheduler extends Component {
  static componentName = 'DemoScheduler';

  static propTypes = {
    size: PropTypes.string,
    match: PropTypes.object,
  };

  sendMessage = (credentials) => {
    if (credentials) {
      this.setState({loading: true, resultMsg: {status: 'One moment...'}}, () => {
        AppAPI.beta.post('', {
          'name': credentials.name,
          'email': credentials.email,
          'phone': credentials.code + credentials.phone,
          'company': credentials.property_name,
          'campaign': 1,
          'content': `
Name: ${credentials.name},
E-mail: ${credentials.email},
Phone: ${credentials.code + credentials.phone},
Job Title: ${credentials.job_title},
Room Size: ${credentials.no_of_rooms},
Property Name: ${credentials.property_name},
          `,
          recaptcha: credentials.recaptcha,
        })
          .then(() => {
            this.setState({resultMsg: {success: 'Success'}},
              () => {
                setTimeout(() => {
                  this.setState({resultMsg: {status: ''}, formSuccess: true, loading: false});
                }, 500);
              });
          })
          .catch((err) => {
            const error = AppAPI.handleError(err);
            this.setState({resultMsg: {error}, loading: false});
          });
      });
    }
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        init: true,
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }


  render = () => {

    const {loading, formSuccess, resultMsg} = this.state;
    const {size, show} = this.props;

    const initialValues = {
      'name': '',
      'email': '',
      'phone': '',
      'job_title': '',
      'no_of_rooms': '',
      'property_name': '',
      'recaptcha': '',
    };

    const formValidation = Yup.object().shape({
      name: Yup.string().min(2, 'Too Short!').required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
      phone: Yup.number().required('Required'),
      code: Yup.string().required('Required'),
      job_title: Yup.string().required('Required'),
      property_name: Yup.string().required('Required'),
      no_of_rooms: Yup.string().required('Required'),
      recaptcha: Yup.string().required('Required'),
    });

    if (!show) {
      return (<React.Fragment/>);
    }

    return (
      <Modal
        {...this.props}
        size={size ? size : 'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Schedule a Demo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className={'row justify-content-md-center'}>
              <div className={'col-sm-12 col-lg-12 col-md-12'}>
                <div className={'row'}>
                  <div className={'col-sm-12 col-lg-4 d-none d-lg-block'}>
                    <h4>See it in Action</h4>

                    <p>Thank you for your interest in Renzo. Drop your contact info and we will provide a free demo
                      tailored for your business.</p>

                    <hr/>
                    <p>You will learn how you can : </p>
                    <ul className="list-unstyled small">
                      <li className="">
                        <p className={''}>
                          <FontAwesomeIcon icon={faCheck} className={'mr-2 green-cl'}/> Cut times on most repeated
                          tasks.</p>
                      </li>

                      <li className="">
                        <p className={''}><FontAwesomeIcon icon={faCheck} className={'mr-2 green-cl'}/> Delegate tasks
                          across departments.</p>
                      </li>

                      <li className="">
                        <p className={''}><FontAwesomeIcon icon={faCheck} className={'mr-2 green-cl'}/> Manage Payments.
                        </p>
                      </li>

                      <li className="">
                        <p className={''}><FontAwesomeIcon icon={faCheck} className={'mr-2 green-cl'}/> Manage day to
                          day expenses.</p>
                      </li>

                    </ul>

                  </div>

                  <div className={'col-sm-12 col-lg-8 border-left'}>
                    <React.Fragment>
                      {formSuccess ?
                        <div>
                          <div className={'p-3 mt-3 mb-0 text-center'}>
                            <div className={'col-md-12 p-lg-2'}>
                              <h1><FontAwesomeIcon icon={faCheckCircle} className={'mr-2 green-cl'}/> Thank you. </h1>
                              <p>We have received your demo request.</p>
                              <p>One of our representatives will contact you shortly to schedule your demo.</p>
                            </div>
                          </div>
                        </div> :
                        <Formik
                          initialValues={initialValues}
                          validationSchema={formValidation}
                          onSubmit={values => this.sendMessage(values)}
                        >
                          {({errors, touched, setFieldValue}) => (
                            <form>
                              <Alerts
                                status={resultMsg.status}
                                success={resultMsg.success}
                                error={resultMsg.error}
                              />

                              <div className={'row'}>
                                <div className={'col-sm-12'}>
                                  <div className="form-row mb-2">
                                    <div className="col-12 form-group">
                                      <Input
                                        required
                                        name='property_name'
                                        label={'Name of your Hotel / Property'}
                                        className={'form-control'}
                                      />
                                    </div>
                                    <div className={'col-12 form-group'}>
                                      <Select
                                        className={'form-control rounded-right-0'}
                                        name='no_of_rooms'
                                        label={'Number of Rooms'}
                                        placeholder='Select an Option'
                                        options={Constants.NO_OF_ROOMS_RANGE}
                                      />
                                    </div>
                                  </div>

                                  <div className="form-row mb-2">
                                    <div className="col-6 form-group">
                                      <Input
                                        required
                                        name='name'
                                        label={'Name'}
                                        className={'form-control'}
                                      />
                                    </div>
                                    <div className="col-6 form-group">
                                      <Input
                                        required
                                        name='job_title'
                                        label={'Job Title'}
                                        className={'form-control'}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-row">
                                    <div className="col-lg-6 col-md-6 form-group">
                                      <Input
                                        required
                                        name='email'
                                        type={'email'}
                                        label={'E-mail'}
                                        className={'form-control'}
                                      />
                                    </div>
                                    <div className="col-lg-6 col-md-6 ">
                                      <div className={'row'}>
                                        <div className={'col-4 pr-0 form-group'}>
                                          <Select
                                            className={'form-control rounded-right-0'}
                                            name='code'
                                            label={'Country'}
                                            placeholder='Select an Option'
                                            options={SelectList.phoneCountryCode()}
                                          />
                                        </div>
                                        <div className={'col-8 pl-0 form-group'}>
                                          <Input
                                            required
                                            type={'tel'}
                                            name='phone'
                                            label={'Phone'}
                                            className={'form-control rounded-left-0'}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>


                                  <div className="form-row">
                                    <div className="col-12 form-group">
                                      <div className={'form-element input'}>
                                        <span className={'label'}>Recaptcha Validation</span>
                                        <Reaptcha
                                          sitekey={AppConfig.reCaptchaKey}
                                          onVerify={(data) => setFieldValue('recaptcha', data)}
                                          onExpire={() => setFieldValue('recaptcha', '')}
                                        />
                                        {errors.recaptcha
                                         && touched.recaptcha && (
                                          <span className={'error'}>{errors.recaptcha}</span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-row">
                                    <div className="col-12 form-group">
                                      <p className={'small text-muted'}>
                                        By submitting, you agree to our
                                        <a
                                          rel='noopener noreferrer'
                                          target={'_blank'} href={'https://renzo.in/policies/'}>
                                          Terms of service & Privacy Policy
                                        </a>.
                                      </p>
                                    </div>
                                    <div className="col-12 form-group">
                                      {loading ? <Loading heightMatch={false} />
                                        :
                                        <SubmitBtn className={'btn btn-success btn-block btn-lg'}>
                                          Submit
                                        </SubmitBtn>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          )}
                        </Formik>
                      }
                    </React.Fragment>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      formSuccess: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
      init: true,
    };
  }
}

/* Export Component ==================================================================== */
export default DemoScheduler;
