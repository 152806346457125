/**
 * Footer
 */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

/* Component ==================================================================== */
class Footer extends Component {

  render = () => {

    return (
      <div className="footer mt-4">
        <div className={'footer-container'}>
          <div className={'container border-top p-3 mx-auto'}>
            <div className={'pb-2 p-md-3'}>
              <div className="row overflow-hidden align-self-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <ul className="footer-links list-inline text-md-center text-sm-center text-lg-left">
                    <li className="list-inline-item">
                      <NavLink
                        to={'/about'}
                        activeClassName="selected"
                        className={'text-dark'}>
                        About</NavLink>
                    </li>
                    <li className="list-inline-item">
                      <NavLink
                        to={'/contact'}
                        activeClassName="selected"
                        className={'text-dark'}>
                        Contact</NavLink>
                    </li>
                    <li className="list-inline-item">
                      <NavLink
                        to={'/policies/privacy'}
                        activeClassName="selected"
                        className={'text-dark'}>Privacy</NavLink>
                    </li>
                    <li className="list-inline-item">
                      <NavLink
                        to={'/policies/terms'}
                        activeClassName="selected"
                        className={'text-dark'}>Terms</NavLink>
                    </li>
                    <li className="list-inline-item">
                      <NavLink
                        to={'/policies/refund-and-cancellation'}
                        activeClassName="selected"
                        className={'text-dark'}>Refunds &amp; Cancellation</NavLink>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 text-lg-right text-sm-center ">
                  <p>Renzo Solutions Pvt Ltd</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default Footer;
