/**
 *
 * Index Page
 * *
 */

import React, {Component} from 'react';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';
import Slider from 'react-slick';
// Config

// Constants
import {Constants} from '../constants';

// Components
import Loading from '../components/general/Loading';
import Error from '../components/general/Error';
import ReEngage1 from '../components/ui/ReEngage1';
import DemoScheduler from './DemoScheduler';

/* Component ==================================================================== */
class Index extends Component {

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplaySpeed: 9000,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true
    };

    const testimonials = Constants.TESTIMONIAL_CONTENT;

    return (
      <div className="index screen-container">
        <Helmet>
          <title>Renzo</title>
        </Helmet>

        <div className={'p-3 mt-3 mb-0 text-center'}>
          <div className={'col-md-12 p-lg-2'}>
            <h1>Supercharge Your Hotel with Renzo</h1>
            <p className={'lead mb-1'}>Simplify & Accelerate every aspect of your hotel operation.</p>
            <p className={'lead'}>Sign up now to power your hotel.</p>
            <div className={'spacer-20'}/>
            <p>
              <button className={'btn btn-success btn-lg mb-4'} onClick={() => this.setState({showDemoScheduler: true})}>
                Get Started - It's Free
              </button>
            </p>
            <p className={'small'}>No setup costs or contract – Start using today</p>
          </div>
        </div>

        <div className={'mx-auto bg-image'}><img className={'img-class'}alt={'Renzo'} src={require('../images/resource/main_bg.svg')}/></div>


        <div className={'container'}>

          <div className="my-md-3 my-sm-2">
            <div
              className="mr-md-3 pt-3 pt-md-5 text-center overflow-hidden">
              <div className="my-3 py-3">
                <h2 className="">You're a property owner with lots to do,</h2>
                <p className="lead">
                  not to learn and figure out tools that ain't built to do what you need.</p>

                <p className="lead">
                  Let us take care of your property management, so you can focus on what matters most.
                </p>
              </div>
            </div>
          </div>

          <div className="d-md-flex flex-md-equal w-150 my-md-3 p-md-3">
            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center">
              <div className={'col-sm-6 mx-auto d-none'}>
                <img
                  className={'d-none d-md-block d-sm-block d-lg-none'} alt={'Renzo'}
                  src={require('../images/resource/freedom.svg')}/>
              </div>

              <h2 className={'text-md-center'}>Super Simple. Super Quick.</h2>

              <div className={'d-xsm-block d-md-block d-sm-block d-lg-none my-3'}>
                <div className={'spacer-20'}/>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../images/screenshots/general/General.png')}/>
                </div>
              </div>


              <ul className="my-5 list-unstyled">
                <li className="my-2">
                  <span className={'fas fa-rocket orange-cl '}/>
                  <h6 className={'d-inline-block badge orange-bg white-cl p-1 mx-1'}>Ease of use</h6>
                  <p className={'text-muted'}>All tasks are organized on the basis of attention
                    they need; eliminating excess data, conversations and stress.</p>
                </li>

                <li className="">
                  <span className={'fas fa-sync blue-cl'}/>
                  <h6 className={'d-inline-block badge blue-bg white-cl p-1 mx-1'}>Instant Sync</h6>
                  <p className={'text-muted'}>Changes are applied across all your devices real-time.</p>
                </li>

                <li className="">
                  <span className={'fas fa-tree green-cl'}/>
                  <h6 className={'d-inline-block badge green-bg white-cl p-1 mx-1'}>Go Green</h6>
                  <p className={'text-muted'}>Manage business digitally. Save paper.</p>
                </li>
              </ul>

              <Link
                to={'/features'}
                className={'btn btn-lg btn-outline-success'}>Explore More Features <i className="fa fa-arrow-right"/>
              </Link>


              <div className={'store-logos mt-5'}>
                <p className={'text-muted mb-1 small'}>Get the app.</p>
                <a className={'mr-4'} href={'https://play.google.com/store/apps/details?id=in.renzo.app'}>
                  <img width={160} alt={'Find on Playstore'} src={require('../images/store-logos/google-play-badge.png')}/>
                </a>
                <a className={'mx-auto'} href={'https://itunes.apple.com/us/app/renzo/id1446702055'}>
                  <img width={160} alt={'Find on AppStore'} src={require('../images/store-logos/apple-store-badge.png')}/>
                </a>
              </div>
            </div>
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../images/screenshots/general/General.png')}/>
              </div>
            </div>
          </div>


          <div className="w-100 my-3 p-md-3">
            <div className="mr-md-3 px-md-5 mb-5 overflow-hidden align-self-center">
              <ul className="features-list-home list-inline text-center">
                <li className="list-inline-item">
                  <Link to={'/features/Booking'} className={'btn btn-outline-dark'}>
                    <span className={'fas red-cl fa-bed'}/> Front Desk
                  </Link>
                </li>

                <li className="list-inline-item">
                  <Link to={'/features/POS'} className={'btn btn-outline-dark'}>
                    <span className={'fas purple-dark-cl fa-credit-card'}/> Point of Sales
                  </Link>
                </li>

                <li className="list-inline-item">
                  <Link to={'/features/Housekeeping'} className={'btn btn-outline-dark'}>
                    <span className={'fas green-light-cl fa-broom'}/> Housekeeping
                  </Link>
                </li>

                <li className="list-inline-item">
                  <Link to={'/features/Payments'} className={'btn btn-outline-dark'}>
                    <span className={'fas green-cl fa-money-bill'}/> Payments
                  </Link>
                </li>

                <li className="list-inline-item">
                  <Link to={'/features/Reviews'} className={'btn btn-outline-dark'}>
                    <span className={'fas orange-cl fa-star-half'}/> Reviews
                  </Link>
                </li>

                <li className="list-inline-item">
                  <Link to={'/features/Expenses'} className={'btn btn-outline-dark'}>
                    <span className={'fas red-cl fa-money-bill-alt'}/> Expenses
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {(testimonials && testimonials.length) &&

           <div className="testimonials">
             <div className="my-md-3 my-sm-2 border-top">
               <div
                 className="text-center overflow-hidden">
                 <div className="my-3 py-3">
                   <h2 className="">Testimonials</h2>
                   <p className="lead">Here’s what our customers have been saying about us.</p>
                 </div>
               </div>
               <div className="mb-5 pb-3">
                 <Slider {...settings}>
                   {
                     testimonials.map((data, i) => {
                       return (
                         <div key={i}>
                           <div className={'row'}>
                             <div className={'col-8 mx-auto'}>
                               {data.content.map((content, i) => {
                                 return (<p className={'mb-1'} key={i}>{content}</p>);
                               })
                               }
                               <blockquote className="blockquote text-center">
                                 <footer className="blockquote-footer">
                                   {data.hotel}
                                   <br/>
                                   <cite title="Source Title">
                                     {data.user}
                                     {data.flag &&
                                      <img
                                        width={15}
                                        src={require(`../images/flags/${data.flag}.png`)}
                                        className={'country-flag d-inline ml-1'}
                                        alt={data.flag}
                                      />
                                     }</cite>
                                 </footer>
                               </blockquote>
                             </div>
                           </div>
                         </div>
                       );
                     })
                   }
                 </Slider>
               </div>
             </div>
           </div>
          }
          <ReEngage1/>

        </div>
        <DemoScheduler
          show={this.state.showDemoScheduler}
          onHide={() => this.setState({showDemoScheduler: false})}
        />
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      showDemoScheduler: false,
      loading: false,
      error: false
    };
  }
}

export default Index;
