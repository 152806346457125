/**
 *
 * Policies
 *
 */

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
// Config
// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';

/* Component ==================================================================== */
class Policies extends Component {

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    return (
      <div className="index screen-container">
        <Helmet>
          <title>Policies</title>
        </Helmet>
        <div className={'main-container container'}>
          <div className={'row justify-content-md-center'}>
            <div className={'col-sm-12 col-lg-8 col-md-10 mx-auto'}>
              <h1 className={'text-center'}>Policies</h1>
              <div className={'spacer-20'}/>

              <p>Thanks for taking the time to learn about Renzo's policies. It's important
                stuff. You'll find information about how we protect your privacy, what you
                can and can't do with Renzo, and how we handle user accounts. If you still have
                questions after reading them, drop us a line.</p>

              <div className={'spacer-20'}/>
              <hr/>

              <div className="d-flex justify-content-center">
                <div className={'p-2'}>
                  <Link to={'/policies/privacy'} className="btn btn-lg btn-outline-primary" href="#">
                    <i className="fa fa-gavel"/> Privacy Policy
                  </Link>
                </div>
                <div className={'p-2'}>
                  <Link to={'/policies/terms'} className="btn btn-lg btn-outline-primary" href="#">
                    <i className="fa fa-user-secret"/> Terms &amp; Conditions
                  </Link>
                </div>

                <div className={'p-2'}>
                  <Link to={'/policies/refund'} className="btn btn-lg btn-outline-primary" href="#">
                    <i className="fa fa-money-bill"/> Refund &amp; Cancellation
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default Policies;