/**
 * User Reducer
 *
 */

// Set initial state
const initialState = {};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'USER_REPLACE': {
    return action.data;
  }

  default:
    return state;
  }
};

export default userReducer;