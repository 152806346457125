/**
 *
 * Contact Information
 *
 */

import React, {Component} from 'react';
import Helmet from 'react-helmet';
// Components
import ReEngage1 from '../components/ui/ReEngage1';

/* Component ==================================================================== */
class About extends Component {


  render = () => {
    return (
      <div className="index screen-container">
        <Helmet>
          <title>About us</title>
        </Helmet>

        <div className={'main-container container'}>
          <div className={'row justify-content-md-center'}>
            <div className={'col-sm-12 col-lg-8 col-md-10'}>
              <h1 className={'text-center'}>About Renzo</h1>
            </div>
          </div>
          <div className={'spacer-20'}/>
          <div className={'row justify-content-md-center'}>
            <div className={'col-sm-12 col-lg-8 col-md-10'}>
              <h4>We're bunch of people out to build great products!</h4>
              <div className={'spacer-20'}/>

              <p>Renzo provides innovative hotel operating system for hotels of all sizes, making it easy for teams to
                manage the hotel operations and keep their guests happy. Renzo -Software-as-a-Service (SaaS) product
                provide a 360-degree view of the hotel, are ready to go, easy to use, and offer a quick return on
                investment. Headquartered in Delaware, USA, Renzo team members work in India & USA to simplify
                hotel-tech. The company counts over 1500 hotels in its community around the world.</p>

              <p>Property Management software is clunky, expensive, hard to setup and frustrating to use. Countless
                hours are wasted to customize these solutions for every single hotel and they often require expensive
                professional services to get everything to work.</p>

              <p>We believe your hotel needs better software - one that's ready to do and works intuitively with your
                routine business process and requires minimal customization. All our products live up to this promise
                and is backed with best in class support.</p>

              <div className={'spacer-20'}/>
            </div>
          </div>
          <ReEngage1/>
        </div>
      </div>
    );
  };

}

export default About;