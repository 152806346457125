/**
 * Global App Config
 *
 */

import AppAPI from './api';

let environment = 'production';
let reCaptchaKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
  environment = process.env.REACT_APP_ENVIRONMENT;
}

if (process.env.REACT_APP_RE_CAPTCHA_KEY) {
  reCaptchaKey = process.env.REACT_APP_RE_CAPTCHA_KEY;
}

export default {
  // App Details
  appName: 'Renzo',
  DEV: (environment !== 'production'),
  environment: environment,
  endpoint: AppAPI.hostname,
  hostname: '//renzo.in',
  reCaptchaKey: reCaptchaKey,
  paymentHostname: '//payments.renzo.in',
  dashboardHostname: '//dashboard.renzo.in'
};
