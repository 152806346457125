export default {
  TESTIMONIAL_CONTENT: [
    {
      'content': [
        'Renzo makes our life easy with their easy to use mobile app. Its easy to on-board employees with minimum tech expertise. Mobile POS and one click booking are really nice features. The icing on the cake is that we no longer need local installations servers,licenses or unnecessary upgrades.',
      ],
      'hotel': 'Hotel Embassy',
      'user': 'Ryan Stone, General Manager',
      'flag': 'us',
    },
    {
      'content': [
        'Now we have discarded all our existing tools. We use Renzo for bookings, payments, housekeeping, accounting, invoicing and sales purposes. The beautiful reports and insights help us take better decisions. Our efficiency and occupancy is increasin predominantly. The support by Renzo team is phenomenal.They always update us with new feature and use cases so we can optimize our property',
      ],
      'hotel': 'Silver Sand Residency',
      'user': 'Geoffrey Miller, Director Sales & Marketing',
      'flag': 'gb',
    },
    {
      'content': [
        'Renzo adds a lot of value to our property with ease of booking system and guest management.The best part of the app is that it is completely paperless. We can distribute our rooms in one click and perform all functions within a couple of clicks.The inbuilt simple review system is really nice. The Renzo support team is really prompt and helpful.',
      ],
      'hotel': 'Hotel Samrat',
      'user': 'Shardul Malhotra, Reservation Manager',
      'flag': 'in',
    },
  ],
  NO_OF_ROOMS_RANGE: [
    {value: 'Less than 10', label: 'Less than 10'},
    {value: '10-20', label: '10-20'},
    {value: '20-50', label: '20-50'},
    {value: '50 and more', label: '50 and more'},
    {value: 'Group of Hotels', label: 'Group of Hotels'},
  ],
  PARTNER_TYPE: [
    {value: 'Technology Partner', label: 'Technology Partner'},
    {value: 'Reseller Partner', label: 'Reseller Partner'},
  ],
  SUBJECT_TYPE: [
    {value: 0, label: 'General Contact'},
    {value: 1, label: 'Technical Support Contact'},
    {value: 2, label: 'Billing Contact'},
  ]
};
