/**
 *
 * Features General Features
 * *
 */

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import ReEngage1 from '../../components/ui/ReEngage1';

/* Component ==================================================================== */
class Index extends Component {

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    return (
      <div className="index screen-container">
        <Helmet>
          <title>Renzo All in one Property Management Solutions</title>
        </Helmet>

        <div className={'p-3 mt-3 mb-0 text-center row justify-content-sm-center'}>
          <div className={'col-md-10 col-lg-8 p-lg-2'}>
            <div className={'row justify-content-sm-center'}>
              <div className={'col-lg-3 col-md-3 col-sm-5 d-none d-sm-block'}>
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/renzo.svg')}/>
              </div>
            </div>
            <h1>Take Control of your property.</h1>
            <p className={'lead'}>Accelerate your business with advance tools that empower your team to deliver the best
              experience for your guests.</p>
            <div className={'spacer-20'}/>


            {/*<div className={'row'}>*/}
            {/*<div className={'col-lg-8 col-md-10 col-sm-12 d-none d-sm-block'}>*/}
            {/*<img alt={'Renzo'} src={require('../../images/screenshots/general/Dashboard-Desktop.png')}/>*/}
            {/*</div>*/}
            {/*</div>*/}

            {/*<p className={'small'}>How it works <br/>*/}
            {/*<i className="fa fa-arrow-down"/>*/}
            {/*</p>*/}
          </div>
        </div>

        {/*<div className={'mx-auto bg-image'}><img alt={'Renzo'} src={require('../../images/resource/main_bg.svg')}/>*/}
        {/*</div>*/}


        <div className={'container'}>


          <div className="d-md-flex flex-md-equal w-150 p-md-3 border-top position-relative center-line">
            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/general/Front-Desk.png')}/>
                </div>
              </div>

              <h2>Front Desk Management</h2>
              <p>Front Desk system allows you to conduct smooth conduct of regular activities such as check-in, check-out,
                booking at ease.</p>

              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Multiple Rate Packages</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Calendar View for easy booking lookups.</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Integrated Billing with Point of Sales.</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Intuitive guest management system.</h6>
                </li>

              </ul>
              <div className={'spacer-20'}/>
              <Link
                to={'/features/Booking'}
                className={'btn btn-success'}>Learn More on Front Desk Management <i className="fa fa-arrow-right"/>
              </Link>
            </div>
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/general/Front-Desk.png')}/>
              </div>
            </div>
          </div>

          <div className="d-md-flex flex-md-equal w-150  p-md-3 position-relative center-line">
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/general/House-Keeping.png')}/>
              </div>
            </div>

            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center right-side-box">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/general/House-Keeping.png')}/>
                </div>
              </div>

              <h2>House Keeping</h2>
              <p>House Keeping system provides real-time information about the room(s) in your property which
                need attention for cleaning or maintenance work(s).</p>

              <p>Streamline workflow of your housekeeping staff(s) with helpful tools.</p>

              <p>Report any change in room condition immediately to the Front Desk or Management for further action(s).</p>
              <div className={'spacer-20'}/>
              <Link
                to={'/features/HouseKeeping'}
                className={'btn btn-success'}>Learn More on House Keeping <i className="fa fa-arrow-right"/></Link>
            </div>
          </div>

          <div className="d-md-flex flex-md-equal w-150 p-md-3 position-relative center-line">
            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/general/Point-Of-Sales.png')}/>
                </div>
              </div>

              <h2>Point of Sales (POS)</h2>
              <p>Point of Sales system provides a set of functions required to maintain a point of sale
                like Spa, Restaurant, Bar etc in your property.</p>


              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Link orders to booking.</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Online payment processing.</h6>
                </li>


              </ul>
              <div className={'spacer-20'}/>
              <Link
                to={'/features/POS'}
                className={'btn btn-success'}>Learn More on Point of Sales <i className="fa fa-arrow-right"/></Link>
            </div>
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/general/Point-Of-Sales.png')}/>
              </div>
            </div>
          </div>

          <div className="d-md-flex flex-md-equal w-150  p-md-3 position-relative center-line">
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/general/Accounts.png')}/>
              </div>
            </div>

            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center right-side-box">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/general/Accounts.png')}/>
                </div>
              </div>

              <h2>Accounts</h2>
              <p>Accounts system allows you to manage billing for any activity in your property.</p>

              <p>Streamline your accounting department with the set of functions.</p>
              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Corporate Billing</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Different Payment modes</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Multiple Payments and Part Payment Options</h6>
                </li>
              </ul>

              <div className={'spacer-20'}/>
              <Link
                to={'/features/POS'}
                className={'btn btn-success'}>Learn More on Accounts <i className="fa fa-arrow-right"/></Link>

            </div>
          </div>


          <div className="d-md-flex flex-md-equal w-150 p-md-3 position-relative center-line">
            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/general/Payment-Management.png')}/>
                </div>
              </div>

              <h2>Payment Management</h2>
              <p>Payment management system allows you to manage day to day revenue inflows.</p>


              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Part Payments.</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Refunds.</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Multiple Payment modes.</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Payment Reference.</h6>
                </li>


              </ul>
              <div className={'spacer-20'}/>
              <Link
                to={'/features/Payments'}
                className={'btn btn-success'}>Learn More on Payment Management System <i className="fa fa-arrow-right"/></Link>
            </div>
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/general/Payment-Management.png')}/>
              </div>
            </div>
          </div>


          <div className="d-md-flex flex-md-equal w-150  p-md-3 position-relative center-line">
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/general/Expense-Management.png')}/>
              </div>
            </div>

            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center right-side-box">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/general/Expense-Management.png')}/>
                </div>
              </div>

              <h2>Expense Management</h2>

              <p>Expense management system allows you to manage day to day expense(s) and procurement of items.</p>


              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Purchase management.</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Record keeping.</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Procurements.</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Vendor Management.</h6>
                </li>
              </ul>

              <div className={'spacer-20'}/>
              <Link
                to={'/features/Expenses'}
                className={'btn btn-success'}>Learn More on Expense Management System <i className="fa fa-arrow-right"/></Link>

            </div>
          </div>

          <div className="d-md-flex flex-md-equal w-150 p-md-3 position-relative center-line">
            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/general/Review-Details.png')}/>
                </div>
              </div>

              <h2>Review Management</h2>
              <p>Get real-time sentiment updates from guest(s) to optimize your hotel experience.</p>


              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Send review request(s) to guest(s) for each booking.</h6>
                </li>


                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Get quick insights for each booking on the go.</h6>
                </li>

              </ul>
              <div className={'spacer-20'}/>
              <Link
                to={'/features/Reviews'}
                className={'btn btn-success'}>Learn More on Review Management System <i className="fa fa-arrow-right"/></Link>
            </div>
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/general/Review-Details.png')}/>
              </div>
            </div>
          </div>

          <ReEngage1/>

        </div>
      </div>
    );
  };

  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}

export default Index;