/**
 * Footer
 */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import DemoScheduler from '../../containers/DemoScheduler';

/* Component ==================================================================== */
class ReEngage1 extends Component {

  render = () => {

    return (
      <div className="re-engage re-engage-1">
        <div className="d-md-flex flex-md-equal w-100 p-md-3 border-top">
          <div
            className="mr-md-3 pt-3 pt-md-5 d-none d-md-none d-sm-none d-lg-block overflow-hidden align-self-center">
            <div className="box-shadow mx-auto index-box-width">
              <img alt={'Renzo'} className={'w-100 img-class'} src={require('../../images/resource/submarine.svg')}/>
            </div>
          </div>
          <div className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center">
            <div className="my-3 py-3">
              <div className={'col-sm-6 mx-auto'}>
                <img
                  className={'img-class d-none d-md-block d-sm-block d-lg-none my-3'} alt={'Renzo'}
                  src={require('../../images/resource/submarine.svg')}/>
              </div>
              <h2 className="display -5">Ditch the chaos.</h2>
              <div className={'my-3'}>
                <p className="lead">
                  Spreadsheets, Sticky notes and E-mail they are great tools but not for what you
                  want. They take time and make it hard for you to work.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mr-md-3 pt-3 px-3 my-5 my-sm-2 text-center overflow-hidden">
          <p className="lead">Your Job shouldn't be boring, it should be fun and easy.</p>
          <p className={'mb-0'}>
            <button className={'btn btn-outline-info btn-lg mb-4'} onClick={() => this.setState({showDemoScheduler: true})}>Schedule Demo </button>
          </p>
          <small>Learn more about our <Link
            to={'/pricing'}
            className={'a'}>pricing</Link></small>
        </div>

        <DemoScheduler
          show={this.state.showDemoScheduler}
          onHide={() => this.setState({showDemoScheduler: false})}
        />

      </div>
    );
  };
  constructor(props) {
    super(props);

    this.state = {
      showDemoScheduler: false,
    };
  }
}

/* Export Component ==================================================================== */
export default ReEngage1;
