/**
 * Header
 */
import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
// Config
import {AppConfig} from '../../constants';

import DemoScheduler from '../../containers/DemoScheduler';

/* Component ==================================================================== */
class Header extends Component {

  render = () => {
    return (
      <div className={'header container'}>
        <div className={'mb-5 border-bottom box-shadow'}>
          <nav className="navbar navbar-expand-lg p-3 px-md-4 bg-white">
            <Link to={'/'} className={'navbar-brand'}>
              <div className={'brand'}><img className={'img-class'} width={120} alt={'Renzo'} src={require('../../images/logo.png')}/></div>
            </Link>
            <button
              className="navbar-toggler  navbar-light" type="button" data-toggle="collapse"
              data-target="#navbarText" aria-controls="navbarText" aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"/>
            </button>
            <div className="collapse navbar-collapse text-sm-center text-xm-center" id="navbarText">
              <ul className="navbar-nav  	mr-auto">
                <li className="nav-item active">
                  <NavLink
                    to={'/features'} activeClassName="selected"
                    className={'p-2 text-dark'}>Features</NavLink>
                </li>
                <li className="nav-item active">
                  <NavLink
                    to={'/pricing'} activeClassName="selected"
                    className={'p-2 text-dark'}>Pricing</NavLink>
                </li>
                <li className="nav-item active">
                  <NavLink
                    to={'/partner'} activeClassName="selected"
                    className={'p-2 text-dark'}>Partners</NavLink>
                </li>
              </ul>

              <span className="navbar-text">
                <a
                  href={AppConfig.dashboardHostname + '/auth/login'}
                  className={'p-2 text-dark mr-3'}>Login</a>
                <button className="btn btn-outline-primary" onClick={() => this.setState({showDemoScheduler: true})}>Schedule Demo </button>
              </span>
            </div>
          </nav>
        </div>
        <DemoScheduler
          show={this.state.showDemoScheduler}
          onHide={() => this.setState({showDemoScheduler: false})}
        />
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      showDemoScheduler: false,
    };
  }
}

/* Export Component ==================================================================== */
export default Header;
