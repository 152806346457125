/**
 *
 * Features Expenses
 * *
 */

import React, {Component} from 'react';
import Helmet from 'react-helmet';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import ReEngage1 from '../../components/ui/ReEngage1';

/* Component ==================================================================== */
class Index extends Component {

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    return (
      <div className="index screen-container">
        <Helmet>
          <title>Expense Management</title>
        </Helmet>

        <div className={'p-3 mt-3 mb-0 text-center'}>
          <div className={'col-md-12 p-lg-2'}>
            <h1>Effortless Expense Management</h1>
            <p className={'lead'}>Know everything about your expense, from anywhere.</p>
            <div className={'spacer-20'}/>

            <p className={'small'}>How it works <br/>
              <i className="fa fa-arrow-down"/>
            </p>
          </div>
        </div>

        <div className={'container'}>


          <div className="d-md-flex flex-md-equal w-150 p-md-3 border-top position-relative center-line">
            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/expense/Express-Expense.png')}/>
                </div>
              </div>

              <h2>Record expenses the right way</h2>
              <p>Record expenses as they happen. Express expense lets you add expense without any
                complication(s).</p>
              <p>Express expense needs the following data</p>

              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Department & Category
                    of expenses.
                  </h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Price of commodity.
                  </h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Mode of payment.</h6>
                </li>

              </ul>
            </div>
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/expense/Express-Expense.png')}/>
              </div>
            </div>
          </div>

          <div className="d-md-flex flex-md-equal w-150  p-md-3 position-relative center-line">
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/expense/Expense-Manage.png')}/>
              </div>
            </div>

            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center right-side-box">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/expense/Expense-Manage.png')}/>
                </div>
              </div>

              <h2>Expense Management</h2>
              <p>Not enough, add more details of your spending and be informed of how it went.</p>

              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Record Cost, Quantity
                    and more.</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Itemised Expense for
                    better clarity.</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Edit or add
                    additional entry.</h6>
                </li>


              </ul>
            </div>
          </div>

          <div className="d-md-flex flex-md-equal w-150 p-md-3 position-relative center-line">
            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/expense/Expense-Details.png')}/>
                </div>
              </div>

              <h2>Keep it digital</h2>
              <p>Paper can fade and become illegible, but digital records never disappear. Store everything you need
                digitally.</p>

              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Attach receipts</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Collect signature
                    from vendors.</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Record mode of
                    payment and its record's.</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Create reference note(s).</h6>
                </li>
              </ul>
            </div>
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/expense/Expense-Details.png')}/>
              </div>
            </div>
          </div>

          <ReEngage1/>

        </div>
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false
    };
  }
}

export default Index;