/**
 *
 * Features Booking
 * *
 */

import React, {Component} from 'react';
import Helmet from 'react-helmet';

// Components
import Loading from '../../components/general/Loading';
import Error from '../../components/general/Error';
import ReEngage1 from '../../components/ui/ReEngage1';
import {Link} from 'react-router-dom';

/* Component ==================================================================== */
class Index extends Component {

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    return (
      <div className="index screen-container">
        <Helmet>
          <title>Booking</title>
        </Helmet>

        <div className={'p-3 mt-3 mb-0 text-center'}>
          <div className={'col-md-12 p-lg-2'}>
            <h1>Create and Manage Bookings with Ease</h1>
            <p className={'lead'}>Easiest way to manage bookings and guests.</p>
            <div className={'spacer-20'}/>

            <p className={'small'}>How it works <br/>
              <i className="fa fa-arrow-down"/>
            </p>
          </div>
        </div>

        {/*<div className={'mx-auto bg-image'}><img alt={'Renzo'} src={require('../../images/resource/main_bg.svg')}/>*/}
        {/*</div>*/}


        <div className={'container'}>


          <div className="d-md-flex flex-md-equal w-150 p-md-3 border-top position-relative center-line">
            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/booking/Booking-Lookup.png')}/>
                </div>
              </div>

              <h2>Booking Lookup</h2>
              <p>Before making a booking a lookup is made to ensure property meets requirements and can take the
                booking.</p>

              <p>Booking lookup needs the following data</p>
              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/> Check-In and
                    Check-Out dates</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/> Number of Rooms</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/> Room Type</h6>
                </li>
              </ul>
            </div>
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/booking/Booking-Lookup.png')}/>
              </div>
            </div>
          </div>

          <div className="d-md-flex flex-md-equal w-150  p-md-3 position-relative center-line">
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/booking/Booking-Details.png')}/>
              </div>
            </div>

            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center right-side-box">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/booking/Booking-Details.png')}/>
                </div>
              </div>

              <h2>Booking Information</h2>
              <p>If Booking lookup is successful then your property is available to take booking(s). Fill in the
                required information to create the booking.</p>

              <p>Booking information needs the following data</p>
              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Booking Package</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Guest count and
                    primary guest Information</h6>
                </li>
              </ul>
            </div>
          </div>

          <div className="d-md-flex flex-md-equal w-150 p-md-3 position-relative center-line">
            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/booking/Booking-Order.png')}/>
                </div>
              </div>

              <h2>Booking Order</h2>
              <p>The system calculates and displays the detailed order summary for booking(s) from the preset prices in
                the package, any changes if required can be made; discounts or offers can be added.</p>

              <p>The following changes are possible</p>
              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Override booking
                    total with a new total (Inc Tax or Exc Tax).</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Add customer tax
                    number</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Discount for
                    individual item or entire order.</h6>
                </li>

              </ul>
            </div>
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/booking/Booking-Order.png')}/>
              </div>
            </div>
          </div>

          <div className="d-md-flex flex-md-equal w-150  p-md-3 position-relative center-line">
            <div
              className="mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center d-none d-md-block  d-lg-block d-xl-block">
              <div className=" box-shadow mx-auto index-box-width text-center">
                <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/booking/Booking-Payment-Confirm.png')}/>
              </div>
            </div>

            <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center right-side-box">
              <div className={'d-xsm-block d-md-none d-sm-block d-lg-none my-5'}>
                <div className=" box-shadow mx-auto index-box-width">
                  <img className={'img-class'}alt={'Renzo'} src={require('../../images/screenshots/booking/Booking-Payment-Confirm.png')}/>
                </div>
              </div>

              <h2>Booking Payment</h2>
              <p>Finalize the booking with payment, You can choose modes types of payments for the booking.</p>
              <p>Choose a modes of payments for the order.</p>
              <ul className="list-unstyled">
                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Cash</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Cheque or Bank
                    Transfer</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Point of Sales
                    Terminal</h6>
                </li>

                <li className="">
                  <h6 className={'d-inline-block'}><span className={'fas fa-check green-cl mr-2'}/>Online Payment</h6>
                </li>
              </ul>
              <div className={'spacer-20'}/>
              <Link
                to={'/features/Payments'}
                className={'btn btn-success'}>Learn More on Payment Management System <i className="fa fa-arrow-right"/></Link>
            </div>
          </div>

          <ReEngage1/>

        </div>
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false
    };
  }
}

export default Index;